import * as React from "react";
import "./App.css";
import "./pages/Concourse/style.less";
import { Layout, Menu } from "antd";
import { inject, observer } from "mobx-react";
import Login from "./login/Login";
import Centered from "./common/Centered";
import AppHeader from "./header/AppHeader";
import {NavLink, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import HomePage from "./home/HomePage";
import { menuItems } from "../routing";
import {
  injectMainStore,
  MainStoreInjected,
  RouteItem,
  SubMenu
} from "@cuba-platform/react";
import { CenteredLoader } from "./CenteredLoader";
import {FormattedMessage, injectIntl, IntlFormatters, WrappedComponentProps} from "react-intl";
import { getMenuIcon } from "../resources/icons/menu";
import { MenuRouteItem, MenuSubMenu } from "./store/MenuStore";
import UserSettings from "./pages/UserSettings/UserSettings";
import { RootStoreProp } from "./store";
import MyKpiPage from "./pages/MyKpi/MyKpiPage";
import { PersonDocumentManagement } from "./pages/PersonDocument/PersonDocumentManagement";
import { PersonContactManagement } from "./pages/PersonContact/PersonContactManagement";
import { AssignedPerformancePlanManagement } from "./pages/Kpi/AssignedPerformancePlanManagement";
import { AssignedGoalManagement } from "./pages/AssignedGoals/IndividualGoal/AssignedGoalManagement";
import { LibraryAssignedGoalManagement } from "./pages/AssignedGoals/LibraryGoal/LibraryAssignedGoalManagement";
import LearningHistory from "./pages/LearningHistory";
import { CourseManagement } from "./pages/Course/CourseManagement";
import { EnrollmentManagement } from "./pages/MyCourse/EnrollmentManagement";
import { KpiTeamManagement } from "./pages/KpiTeam/KpiTeamManagement";
import { BooksManagement } from "./pages/Books/BooksManagement";
import { OrgStructureRequestManagement } from "./pages/orgStructureRequest/OrgStructureRequestManagement";
import { InsuredPersonManagement } from "./pages/MyDMC/InsuredPersonManagement";
import { CertificateRequestManagement } from "./pages/CertificateRequest/CertificateRequestManagement";
import { ActivityManagement } from "./pages/Activity/ActivityManagement";
import { AbsenceRequestManagement } from "./pages/AbsenceRequest/AbsenceRequestManagement";
import AbsenceList from "./pages/Absence/AbsenceList";
import ConcourseList from "./pages/Concourse/ConcourseList";
import { LeavingVacationRequestManagement } from "./pages/LeavingVacationRequest/LeavingVacationRequestManagement";
import { CascadeGoalManagement } from "./pages/AssignedGoals/CascadeGoal/CascadeGoalManagement";
import { VacationScheduleRequestManagement } from "./pages/VacationScheduleRequest/VacationScheduleRequestManagement";
import MyEducation from "./pages/MyEducation/MyEducation";
import MyEducationManagement from "./pages/MyEducation/MyEducationManagement";
import LearningBudgetManagement from "./pages/LearningBudget/LearningBudgetManagement";
import LearningHistoryManagement from "./pages/LearningHistory/LearningHistoryManagement";
import { PortalFeedbackQuestionManagement } from "./pages/PortalFeedbackQuestions/PortalFeedbackQuestionManagement";
import { MyTeamStructureManagement } from "./pages/MyTeam/MyTeamStructureManagement";
import { ChangeAbsenceDaysRequestManagement } from "./pages/MyTeam/timeManagement/ChangeAbsenceDaysRequest/ChangeAbsenceDaysRequestManagement";
import { AbsenceForRecallManagement } from "./pages/MyTeam/timeManagement/AbsenceForRecall/AbsenceForRecallManagement";
import { AbsenceRvdRequestManagement } from "./pages/MyTeam/timeManagement/rvd/MyTeamPersonRvdRequest/AbsenceRvdRequestManagement";
import { ScheduleOffsetsRequestManagement } from "./pages/ScheduleOffsetsRequest/ScheduleOffsetsRequestManagement";
import { ExecutiveAssistantsManagement } from "./pages/ExecutiveAssistants/ExecutiveAssistantsManagement";
import { MyProfileManagement } from "./pages/MyProfile/MyProfileManagement";
import { PersonEducationManagement } from "./pages/PersonEducation/PersonEducationManagement";
import { PersonalDataRequestManagement } from "./pages/PersonalDataRequest/PersonalDataRequestManagement";
import { PersonDocumentRequestManagement } from "./pages/PersonDocumentRequest/PersonDocumentRequestManagement";
import { AddressRequestManagement } from "./pages/AddressRequest/AddressRequestManagement";
import { MartialStatusRequestManagement } from "./pages/MartialStatusRequest/MartialStatusRequestManagement";
import { VacationScheduleManagement } from "./pages/VacationSchedule/VacationScheduleManagement";
import { PersonPayslipManagement } from "./pages/PersonPayslip/PersonPayslipManagement";
import { IncentiveManagement } from "./pages/Incentive/IncentiveManagement";
import { BpmUserSubstitutionManagement } from "./pages/BpmUserSubstitution/BpmUserSubstitutionManagement";
import { PunishmentAssignmentRequestManagement } from "./pages/MyTeam/assignment/punishmentAssignmentRequest/PunishmentAssignmentRequestManagement";
import { PunishmentRemovalRequestManagement } from "./pages/MyTeam/assignment/punishmentRemovalRequest/punishmentRemovalRequestManagement";
import { PositionHierarchyManagement } from "./pages/PositionHierarchy/PositionHierarchyManagement";
import { WccfManagement } from "./pages/Wccf/WccfManagement";
import { HrRequestManagerManagement } from "./pages/HrRequestManager/HrRequestManagerManagement";
import { IncentiveApproveManagement } from "./pages/IncentiveApprove/IncentiveApproveManagement";
import ucoIcon from "../resources/icons/uco.png";

import CbaAssessmentList, {
  cbaAssessmentPATH
} from "./pages/Cbaforms/CbaAssessment";
import { PositionOverlappingRequestManagement } from "./pages/PositionOverlappingRequest/PositionOverlappingRequestManagement";
import { VacancyRequestManagement } from "./pages/VacancyRequest/VacancyRequestManagement";
import { JobDescriptionRequestManagement } from "./pages/JobDescriptionRequest/JobDescriptionRequestManagement";
import Questionnaire from "./questionnaire/Questionnaire";
import { DictionaryEmployeeManagement } from "./pages/DictionaryEmployee/DictionaryEmployeeManagement";
import { DocumentsFamiliarizationManagement } from "./pages/DocumentsFamiliarization/DocumentsFamiliarizationManagement";
import { DismissalRequestManagement } from "./pages/DismissalRequest/DismissalRequestManagement";
import QuestionnaireManagement from "./questionnaire/QuestionnaireManagement";
import { CalendarManagement } from "./pages/MyCalendar/CalendarManagement";
import MyCalendar from "./pages/MyCalendar/MyCalendar";
import WccfMenuPage from "./pages/Wccf/WccfMenuPage";
import { AnnualSalaryRevisionManagement } from "./pages/AnnualSalaryRevision/AnnualSalaryRevisionManagement";
import LearningBudgetRequestManagement from "./pages/LearningBudget/LearningBudgetRequestManagement";
import { ApprovmentManagement } from "./pages/LearningBudget/Approvment/ApprovmentManagement";
import organizationVacancyList, {
  organizationVacancyListPATH
} from "./pages/OrganizationVacancy/OgranizationVacancyList";
import organizationVacancyInfo, {
  organizationVacancyInfoPATH
} from "./pages/OrganizationVacancy/OrganizationVacancyInfo";
import organizationVacancyRecommend, {
  organizationVacancyRecommendPATH
} from "./pages/OrganizationVacancy/OrganizationVacancyRecommend";
import organizationVacancyRespond, {
  organizationVacancyRespondPATH
} from "./pages/OrganizationVacancy/OrganizationVacancyRespond";
import { PersonContactRequestManagement } from "./pages/PersonContactRequest/PersonContactRequestManagement";
import { PersonEducationRequestManagement } from "./pages/PersonEducationRequest/PersonEducationRequestManagement";
import EmployeeQuestionnaireList, {
  employeeQuestionnaireTablePATH
} from "./pages/employeeQuestionnaire/employeeQuestionnaireTable";
import QuestionnaireExecutionList, {
  EmployeeQuestionnaireExecutionListPath
} from "./pages/employeeQuestionnaire/questionnaireExecutionList";
import { RecognitionManagement } from "./pages/Recognition/RecognitionManagement";
import { BankDetailsRequestManagement } from "./pages/BankDetailsRequest/BankDetailsRequestManagement";
import { LearningRequestManagement } from "./pages/LearningRequest/LearningRequestManagement";
import { SurChargeRequestManagement } from "./pages/SurChargeRequest/SurChargeRequestManagement";
import { PaidSurChargeListManagment } from "./pages/SurChargeRequest/PaidSurChargeList";
import { MySurChargeRequestManagement } from "./pages/SurChargeRequest/MySurChargeRequestManagement";
import PersonMedalRequestManagement from "./pages/Recognition/PersonMedal/PersonMedalRequestManagement";
import { PersonExperienceRequestManagement } from "./pages/PersonExpirienceRequest/PersonExperienceRequestManagement";
import TalentPoolProgram, {
  TalentPoolProgramComponent
} from "./pages/TalentPool/TalentPoolProgram";
import { KeyPositionManagement } from "./pages/TalentPool/KeyPosition/KeyPositionManagement";
import { SuccessorRequestManagement } from "./pages/SuccessorRequest/SuccessorRequestManagement";
import TalentPoolProgramList from "./pages/TalentPoolProgram/TalentPoolProgramList";
import { TrainingPlanManagement } from "./pages/TrainingPlan/TrainingPlanManagement";
import { IndividualDevolopmentPlanManagement } from "./pages/IndividualDevolopmentPlan/IndividualDevolopmentPlanManagement";
import { VacancyRequestAssistantManagement } from "./pages/VacancyRequestAssistant/VacancyRequestAssistantManagement";
import Cascade from "./pages/AssignedGoals/CascadeGoal/Cascade";
import RvdList from "./pages/HrManagerRequestRvd/RvdList";
import { ConcourseRequestManagement } from "./pages/ConcourseRequest/ConcourseRequestManagement";
import { ConcourseManagement } from "./pages/Concourse/ConcourseManagement";
import { PersonQualificationRequestManagement } from "./pages/PersonQualificationRequest/PersonQualificationRequestManagement";
import JobDescriptionList from "./pages/HrJobDescription/JobDescriptionList";
import { PersonAwardsDegreesRequestManagement } from "./pages/PersonAwardsDegreesRequest/PersonAwardsDegreesRequestManagement";
import ScheduleOffsetList from "./pages/HrScheduleOffset/ScheduleOffsetList";
import { MilitaryFormRequestManagement } from "./pages/MilitaryFormRequest/MilitaryFormRequestManagement";
import AllRequests from "./pages/AllRequests/AllRequests";
import { DisabilityRequestManagement } from "./pages/DisabilityRequest/DisabilityRequestManagement";
import PunishmentRequestList from "./pages/PunishmentRequestList/PunishmentRequestList";
import PunishmentRequestListAll from "./pages/PunishmentRequestList/PunishmentRequestList";
import { SuccessionPlanningRequestManagement } from "./pages/SuccessionPlanningRequest/SuccessionPlanningRequestManagement";
import { JobOfferRequestManagement } from "./pages/JobOffer/JobOfferRequestManagement";
import EmployeeRecognitionList from "./pages/EmployeeRecognitionPage/EmployeeRecognitionList";
import RequestHistoryPage from "./pages/AllRequests/RequestHistoryPage";
import MaterialHelpList from "./pages/MaterialHelpPage/MaterialHelpList";
import CourseScheduleList from "./pages/CourseSchedule/CourseScheduleList";
import { TimeLineItemFull } from "./home/timeline/TimeLineItemFull";
import { VacancyManagement } from "./pages/Vacancies/VacancyManagement";
import { CandidateJobRequestManagement } from "./pages/Vacancies/CandidatejobRequestManagement";
import ForNewEmployee from "./pages/AdaptationPlan/ForNewEmployee/ForNewEmployee";
import { AdaptationPlanEditPage } from "./pages/AdaptationPlan/Managment/AdaptationPlanEditPage";
import { PersonOtherInfoRequestManagement } from "./pages/PersonOtherInfoRequest/PersonOtherInfoRequestManagement";
import PunishmentRemovalList from "./pages/PunishmentRemoval/PunishmentRemovalList";
import JobCombineList from "./pages/JobCombineGeneral/JobCombineList";

import NineBoxManagement from "./pages/NineBox/NineBoxManagement";
import { IdpMeetingManagement } from "./pages/IndividualDevolopmentPlan/IdpMeetingManagement";
import {observable} from "mobx";
import StudentsRatingManagment from "./pages/StudentsRating/StudentsRatingManagment";
import RecognitionPageManager from "./pages/RecognitionProgram/Pages/RecognitionPageManager";
import PersonPage from "./pages/RecognitionProgram/Pages/PersonPage";
import StorePage from "./pages/RecognitionProgram/Pages/ShopPage";
import FAQRecognitionPage from "./pages/RecognitionProgram/Pages/FAQRecognitionPage";
import MenuCbaManagement, {MenuCbaManagementComponent} from "./pages/MenuCba/MenuCbaManagement";
import RecognitionProgram, {RecognitionProgramComponent} from "./pages/RecognitionProgram/Pages/RecognitionProgram";


@injectMainStore
@inject("rootStore")
@observer
class AppComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps> {
  @observable collapsed:boolean = false

  onCollapse = (collapsed:any) => {
    this.collapsed = collapsed
  };

  render() {
    const loadedMenu = this.props.rootStore!.menu.menuCustomization;

    const {
      initialized,
      locale,
      loginRequired,
      metadata,
      messages
    } = this.props.mainStore!;

    if (
      !initialized ||
      !locale ||
      !this.props.rootStore!.userInfo.initialized
    ) {
      return <CenteredLoader />;
    }

    if (this.props.rootStore!.initIdpStore.isSso &&
      this.props.rootStore!.initIdpStore.isSsoLoading) {
      return <CenteredLoader />;
    }

    if (loginRequired) {
      return (
        <div>
          <Centered>
            <Login />
          </Centered>
        </div>
      );
    }

    if (!metadata || !messages) {
      return <CenteredLoader />;
    }

    const menuIdx = 1;
    return (
      <>
        <Layout.Header
          className={"h-header header"}
          style={{
            position: "fixed",
            width: "100%",
            height: "64px",
            background: "#fff!important"
          }}
        >
          <AppHeader />
        </Layout.Header>
        <Layout>
          <Layout style={{ marginTop: "64px", minHeight: 'calc(100vh - 64px)'}}>

            <Layout.Sider
              style={{
                background: "#fff",
                overflow: "auto",
                overflowX: "hidden",
                height: "calc(100% + -112px)",
                position: "fixed",
                left: 0
              }}
              collapsible
              collapsed={this.collapsed}
              onCollapse={this.onCollapse}
            >
              <Menu
                mode="inline"
              >
                {menuItems.filter(value => loadedMenu.find(menu => menu.menuItem === value['id']))
                  .map((item, idx) =>
                    this.menuItem(item, "" + (idx + 1 + menuIdx), this.props.intl)
                  )}

                <Menu.Item style={{height: this.collapsed ? 40 : "auto"}}>
                  <a style={{height: this.collapsed ? "34px" : "auto"}}
                     href={this.props.rootStore!.menu.ucoPortalUrl ? this.props.rootStore!.menu.ucoPortalUrl : ""}>
                    <img src={ucoIcon} className={"anticon anticon-desktop ant-menu-item-icon"}/>
                    <span><FormattedMessage id="menu.to.uco.portal"/></span>
                  </a>
                </Menu.Item>
              </Menu>
            </Layout.Sider>
            <Layout >

              <Layout.Content className={this.collapsed?"layout-collapsed":"layout-default"}>
                <Switch>
                  <Route exact={true} path="/" component={HomePage} />
                  <Route
                    exact={true}
                    path="/user/settings"
                    component={UserSettings}
                  />
                  <Route exact={true} path="/my-kpi" component={MyKpiPage} />
                  <Route exact={true} path="/recognition/person/:groupId" component={PersonPage} />
                  <Route exact={true} path="/recognition" component={RecognitionPageManager} />
                  <Route exact={true} path="/recognition/store/*" component={StorePage} />
                  <Route exact={true} path="/recognition/faq" component={FAQRecognitionPage} />
                  <Route exact={true} path={RecognitionProgramComponent.PATH} component={RecognitionProgram} />
                  <Route
                    exact={true}
                    path={"/talentPoolProgram-list"}
                    component={TalentPoolProgramList}
                  />
                  <Route
                    exact={true}
                    path="/position-overlapping-request"
                    component={PositionOverlappingRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PositionOverlappingRequestManagement.PATH +
                      "/:entityId/:personGroupId?"
                    }
                    component={PositionOverlappingRequestManagement}
                  />
                  <Route
                    path="/positionOverlappingRequest/:entityId"
                    component={PositionOverlappingRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={MyProfileManagement.PATH}
                    component={MyProfileManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      AddressRequestManagement.PATH + "/:entityId/:addressId?"
                    }
                    component={AddressRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      MartialStatusRequestManagement.PATH +
                      "/:entityId/:statusId?"
                    }
                    component={MartialStatusRequestManagement}
                  />
                  <Route exact={true} path={"/" + StudentsRatingManagment.PATH} component={StudentsRatingManagment}/>
                  <Route
                    exact={true}
                    path={
                      BankDetailsRequestManagement.PATH +
                      "/:entityId/:bankDetailsId?"
                    }
                    component={BankDetailsRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonQualificationRequestManagement.PATH +
                      "/:entityId/:addressId?"
                    }
                    component={PersonQualificationRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonAwardsDegreesRequestManagement.PATH +
                      "/:entityId/:addressId?"
                    }
                    component={PersonAwardsDegreesRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonOtherInfoRequestManagement.PATH +
                      "/:entityId/:personOtherInfoId?"
                    }
                    component={PersonOtherInfoRequestManagement}
                  />
                  <Route
                    path="/personalDataRequest/:entityId"
                    component={PersonalDataRequestManagement}
                  />
                  <Route
                    path="/personDocumentRequest/:entityId/:documentId?"
                    component={PersonDocumentRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      MilitaryFormRequestManagement.PATH +
                      "/:entityId/:militaryFormId?"
                    }
                    component={MilitaryFormRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      DisabilityRequestManagement.PATH +
                      "/:entityId/:disabilityId?"
                    }
                    component={DisabilityRequestManagement}
                  />
                  <Route
                    path="/personDocumentManagement/:entityId?"
                    component={PersonDocumentManagement}
                  />
                  <Route
                    path="/personEducationManagement/:entityId?"
                    component={PersonEducationManagement}
                  />
                  <Route
                    path={ExecutiveAssistantsManagement.PATH}
                    component={ExecutiveAssistantsManagement}
                  />
                  <Route
                    path="/personContactManagement/:entityId?"
                    component={PersonContactManagement}
                  />
                  <Route
                    exact={true}
                    path={CertificateRequestManagement.PATH + "/:entityId?"}
                    component={CertificateRequestManagement}
                  />
                  <Route
                    exact={true}
                    path="/kpi/:entityId?"
                    component={AssignedPerformancePlanManagement}
                  />
                  <Route
                    exact={true}
                    path="/kpi/:appId/goal/individual/:entityId?"
                    component={AssignedGoalManagement}
                  />
                  <Route
                    exact={true}
                    path="/kpi/:appId/goal/library/:entityId?"
                    component={LibraryAssignedGoalManagement}
                  />
                  <Route
                    exact={true}
                    path="/kpi/:appId/goal/cascade/:entityId?"
                    component={CascadeGoalManagement}
                  />
                  <Route
                    exact={true}
                    path="/goal/cascade"
                    component={Cascade}
                  />
                  <Route
                    exact={true}
                    path={"/" + LearningHistoryManagement.PATH}
                    component={LearningHistory}
                  />
                  <Route
                    exact={true}
                    path="/course/:entityId?"
                    component={CourseManagement}
                  />
                  <Route
                    exact={true}
                    path="/kpi-team/:entityId?"
                    component={KpiTeamManagement}
                  />
                  <Route
                    exact={true}
                    path="/my-books/:entityId?"
                    component={KpiTeamManagement}
                  />
                  <Route
                    exact={true}
                    path="/my-dmc/:entityId?/:contractId?"
                    component={InsuredPersonManagement}
                  />
                  <Route
                    exact={true}
                    path={ScheduleOffsetsRequestManagement.PATH_WITH_PARAMS}
                    component={ScheduleOffsetsRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={"/" + BooksManagement.PATH + "/:entityId?"}
                    component={BooksManagement}
                  />
                  <Route
                    exact={true}
                    path={"/" + "scheduleOffsetList"}
                    component={ScheduleOffsetList}
                  />
                  <Route
                    exact={true}
                    path={"/" + MyEducationManagement.PATH}
                    component={MyEducation}
                  />
                  <Route
                    exact={true}
                    path={"/" + LearningBudgetManagement.PATH}
                    component={LearningBudgetManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      "/" + LearningBudgetRequestManagement.PATH_WITH_PARAMS
                    }
                    component={LearningBudgetRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      "/" +
                      LearningBudgetRequestManagement.PATH_WITH_PARAMS_GROUP
                    }
                    component={LearningBudgetRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      "/" +
                      EnrollmentManagement.PATH +
                      "/:entityId?" +
                      "/:homework?"
                    }
                    component={EnrollmentManagement}
                  />
                  <Route
                    exact={true}
                    path={KeyPositionManagement.PATH}
                    component={KeyPositionManagement}
                  />
                  <Route
                    exact={true}
                    path={ActivityManagement.PATH}
                    component={ActivityManagement}
                  />
                  <Route exact={true}
                         path={AbsenceRequestManagement.PATH + "/:entityId/:employeeId?"}
                         component={AbsenceRequestManagement}/>
                  />
                  <Route
                    exact={true}
                    path="/absence/:activeTab?"
                    component={AbsenceList}
                  />
                  <Route
                    exact={true}
                    path="/concourse/:activeTab?"
                    component={ConcourseList}
                  />
                  <Route
                    exact={true}
                    path={LeavingVacationRequestManagement.PATH + "/:entityId"}
                    component={LeavingVacationRequestManagement}
                  />
                  <Route
                    exact={true}
                    path="/requestHistoryPage/:personId?"
                    component={RequestHistoryPage}
                  />
                  <Route
                    exact={true}
                    path={"/courseScheduleList"}
                    component={CourseScheduleList}
                  />
                  <Route
                    exact={true}
                    path={"/courseScheduleList"}
                    component={CourseScheduleList}
                  />
                  <Route
                    exact={true}
                    path={
                      VacationScheduleRequestManagement.PATH +
                      "/:entityId/:chartType?"
                    }
                    component={VacationScheduleRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={"/jobDescriptionList"}
                    component={JobDescriptionList}
                  />
                  <Route
                    exact={true}
                    path={"/jobDescriptionList"}
                    component={JobDescriptionList}
                  />
                  <Route
                    exact={true}
                    path={"/materialHelpPage"}
                    component={MaterialHelpList}
                  />
                  <Route
                    exact={true}
                    path={PortalFeedbackQuestionManagement.PATH}
                    component={PortalFeedbackQuestionManagement}
                  />
                  <Route
                    exact={true}
                    path={"/allRequestsPage"}
                    component={AllRequests}
                  />
                  <Route
                    exact={true}
                    path={"/punishmentRequestList"}
                    component={PunishmentRequestListAll}
                  />
                  <Route
                    exact={true}
                    path={"/punishmentRemovalList"}
                    component={PunishmentRemovalList}
                  />
                  <Route
                    exact={true}
                    path={"/jobCombineList"}
                    component={JobCombineList}
                  />
                  <Route
                    exact={true}
                    path={NineBoxManagement.PATH + "/:menu?"}
                    component={NineBoxManagement}
                  />
                  <Route
                    exact={true}
                    path="/orgStructureRequest/:entityId?"
                    component={OrgStructureRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={MyTeamStructureManagement.PATH}
                    component={MyTeamStructureManagement}
                  />
                  <Route
                    exact={true}
                    path={RecognitionManagement.PATH}
                    component={RecognitionManagement}
                  />
                  <Route
                    exact={true}
                    path={"/" + PersonMedalRequestManagement.PATH_WITH_PARAMS}
                    component={PersonMedalRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={VacancyManagement.PATH}
                    component={VacancyManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      CandidateJobRequestManagement.PATH +
                      "/:entityId?" +
                      "/:status?"
                    }
                    component={CandidateJobRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PositionHierarchyManagement.PATH}
                    component={PositionHierarchyManagement}
                  />
                  <Route
                    exact={true}
                    path={VacationScheduleManagement.PATH + "/:type"}
                    component={VacationScheduleManagement}
                  />
                  <Route
                    exact={true}
                    path={AbsenceRvdRequestManagement.PATH_WITH_PARAMS}
                    component={AbsenceRvdRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PersonPayslipManagement.PATH}
                    component={PersonPayslipManagement}
                  />
                  {/*<Route exact={true}*/}
                  {/*       path={CurrentScheduleRequestManagement.PATH + "/:entityId"}*/}
                  {/*       component={CurrentScheduleRequestManagement}/>*/}
                  <Route
                    exact={true}
                    path={ConcourseRequestManagement.PATH + "/:entityId?"}
                    component={ConcourseRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={ConcourseManagement.PATH + "/:entityId?"}
                    component={ConcourseManagement}
                  />
                  <Route
                    exact={true}
                    path={ChangeAbsenceDaysRequestManagement.PATH_WITH_PARAMS}
                    component={ChangeAbsenceDaysRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={"/employeeRecognitionPage"}
                    component={EmployeeRecognitionList}
                  />
                  <Route
                    exact={true}
                    path={AbsenceForRecallManagement.PATH_WITH_PARAMS}
                    component={AbsenceForRecallManagement}
                  />
                  <Route
                    exact={true}
                    path={BpmUserSubstitutionManagement.PATH + "/:entityId?"}
                    component={BpmUserSubstitutionManagement}
                  />
                  <Route
                    exact={true}
                    path={BpmUserSubstitutionManagement.PATH + "/:entityId?"}
                    component={BpmUserSubstitutionManagement}
                  />
                  <Route
                    exact={true}
                    path={IncentiveManagement.PATH + "/:entityId?"}
                    component={IncentiveManagement}
                  />
                  {/*<Route exact={true}*/}
                  {/*       path={PATH + "/:entityId?"}*/}
                  {/*       component={CourseScheduleListComponent}/>*/}
                  <Route
                    exact={true}
                    path={TrainingPlanManagement.PATH + "/:entityId?"}
                    component={TrainingPlanManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      IndividualDevolopmentPlanManagement.PATH + "/:entityId?"
                    }
                    component={IndividualDevolopmentPlanManagement}
                  />
                  <Route
                    exact={true}
                    path={IncentiveApproveManagement.PATH + "/:entityId"}
                    component={IncentiveApproveManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PunishmentAssignmentRequestManagement.PATH_WITH_PARAMS
                    }
                    component={PunishmentAssignmentRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PunishmentRemovalRequestManagement.PATH_WITH_PARAMS}
                    component={PunishmentRemovalRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={WccfManagement.PATH + "/:entityId"}
                    component={WccfManagement}
                  />
                  <Route
                    exact={true}
                    path={WccfMenuPage.PATH}
                    component={WccfMenuPage}
                  />
                  <Route
                    exact={true}
                    path={HrRequestManagerManagement.PATH}
                    component={HrRequestManagerManagement}
                  />
                  <Route
                    exact
                    path={
                      SurChargeRequestManagement.PATH + "/user/:personGroupId"
                    }
                    component={SurChargeRequestManagement}
                  />
                  <Route
                    exact
                    path={
                      SurChargeRequestManagement.PATH +
                      "/:entityId?/:personGroupId?"
                    }
                    component={SurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={MySurChargeRequestManagement.PATH + "/:entityId?"}
                    component={MySurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PaidSurChargeListManagment.PATH}
                    component={PaidSurChargeListManagment}
                  />
                  <Route
                    exact={true}
                    path={`/${ApprovmentManagement.PATH}`}
                    component={ApprovmentManagement}
                  />
                  <Route
                    exact={true}
                    path={VacancyRequestManagement.PATH + "/:entityId?"}
                    component={VacancyRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={JobDescriptionRequestManagement.PATH + "/:entityId?"}
                    component={JobDescriptionRequestManagement}
                  />
                  <Route
                    path={DismissalRequestManagement.PATH + "/:entityId?"}
                    component={DismissalRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={TalentPoolProgramComponent.PATH + "/:entityId?"}
                    component={TalentPoolProgram}
                  />
                  <Route
                    exact={true}
                    path={QuestionnaireManagement.PATH}
                    component={Questionnaire}
                  />
                  <Route
                    exact={true}
                    path={DictionaryEmployeeManagement.PATH}
                    component={DictionaryEmployeeManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      DocumentsFamiliarizationManagement.PATH + "/:entityId?"
                    }
                    component={DocumentsFamiliarizationManagement}
                  />
                  <Route
                    exact={true}
                    path={"/" + CalendarManagement.PATH}
                    component={MyCalendar}
                  />
                  <Route
                    exact={true}
                    path={AnnualSalaryRevisionManagement.PATH + "/:entityId"}
                    component={AnnualSalaryRevisionManagement}
                  />
                  <Route
                    exact={true}
                    path={AnnualSalaryRevisionManagement.PATH}
                    component={AnnualSalaryRevisionManagement}
                  />
                  <Route
                    exact={true}
                    path={organizationVacancyListPATH + "/:entityId?"}
                    component={organizationVacancyList}
                  />
                  <Route
                    exact={true}
                    path={organizationVacancyInfoPATH + "/:entityId?"}
                    component={organizationVacancyInfo}
                  />
                  <Route
                    exact={true}
                    path={organizationVacancyRecommendPATH + "/:entityId?"}
                    component={organizationVacancyRecommend}
                  />
                  <Route
                    exact={true}
                    path={organizationVacancyRespondPATH + "/:entityId?"}
                    component={organizationVacancyRespond}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonContactRequestManagement.PATH +
                      "/:entityId/:addressId?"
                    }
                    component={PersonContactRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonEducationRequestManagement.PATH +
                      "/:entityId/:addressId?"
                    }
                    component={PersonEducationRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={employeeQuestionnaireTablePATH + "/:entityId?"}
                    component={EmployeeQuestionnaireList}
                  />
                  <Route
                    exact={true}
                    path={VacancyRequestManagement.PATH + "/:entityId?"}
                    component={VacancyRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={SuccessorRequestManagement.PATH + "/:entityId?"}
                    component={SuccessorRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      EmployeeQuestionnaireExecutionListPath + "/:entityId?"
                    }
                    component={QuestionnaireExecutionList}
                  />
                  <Route
                    exact={true}
                    path={LearningRequestManagement.PATH + "/:entityId"}
                    component={LearningRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={SurChargeRequestManagement.PATH + "/:entityId?"}
                    component={SurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={MySurChargeRequestManagement.PATH + "/:entityId?"}
                    component={MySurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PaidSurChargeListManagment.PATH}
                    component={PaidSurChargeListManagment}
                  />
                  <Route
                    exact={true}
                    path={
                      PersonExperienceRequestManagement.PATH +
                      "/:entityId/:addressId?"
                    }
                    component={PersonExperienceRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={
                      VacancyRequestAssistantManagement.PATH + "/:entityId?"
                    }
                    component={VacancyRequestAssistantManagement}
                  />
                  <Route exact={true} path={"/rvdList"} component={RvdList} />
                  <Route
                    exact
                    path={
                      SurChargeRequestManagement.PATH + "/user/:personGroupId"
                    }
                    component={SurChargeRequestManagement}
                  />
                  <Route
                    exact
                    path={SurChargeRequestManagement.PATH + "/:entityId?"}
                    component={SurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={MySurChargeRequestManagement.PATH + "/:entityId?"}
                    component={MySurChargeRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={PaidSurChargeListManagment.PATH}
                    component={PaidSurChargeListManagment}
                  />
                  <Route
                    exact={true}
                    path={
                      SuccessionPlanningRequestManagement.PATH + "/:entityId?"
                    }
                    component={SuccessionPlanningRequestManagement}
                  />
                  <Route
                    exact
                    path={JobOfferRequestManagement.PATH + "/:entityId?"}
                    component={JobOfferRequestManagement}
                  />
                  <Route
                    exact={true}
                    path={TimeLineItemFull.PATH + "/:entityId?"}
                    component={TimeLineItemFull}
                  />
                  <Route
                    exact={true}
                    path="/for-new-employee"
                    component={ForNewEmployee}
                  />
                  <Route
                    exact={true}
                    path={MenuCbaManagementComponent.PATH + "/:personAssesmentId?"}
                    component={MenuCbaManagement}
                  />
                  <Route
                    exact={true}
                    path={AdaptationPlanEditPage.PATH + "/:entityId"}
                    component={AdaptationPlanEditPage}
                  />
                  <Route
                    exact={true}
                    path={IdpMeetingManagement.PATH + "/:entityId"}
                    component={IdpMeetingManagement}
                  />
                </Switch>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
  componentDidUpdate(prevProps:MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  menuItem = (
    item: RouteItem | SubMenu,
    keyString: string,
    intl: IntlFormatters
  ) => {
    // Sub Menu  const
    if ((item as any).items != null && (item as any).id !== "allRequests") {
      const loadedMenu = this.props.rootStore!.menu.menuCustomization;

      //recursively walk through sub menus
      const menuSubMenu: MenuSubMenu = item as MenuSubMenu;
      const MenuIcon = getMenuIcon(menuSubMenu.id);
      return (
        <Menu.SubMenu
          key={keyString}
          title={
            <span>
              {MenuIcon
                ? <img src={MenuIcon} className={"anticon anticon-desktop ant-menu-item-icon"}/>
                : null}
              <span>  {this.props.intl.formatMessage({id: "menu." + (item as any).id})}</span>
          </span>
          }
        >
          {(item as SubMenu).items.filter(value => loadedMenu.find(menu => menu.menuItem === value['id'])).map((ri, index) =>
            this.menuItem(ri, keyString + "-" + (index + 1), intl)
          )}
        </Menu.SubMenu>
      );
    }

    // Route Item
    const menuRouteItem: MenuRouteItem = item as MenuRouteItem;
    const MenuIcon = getMenuIcon(menuRouteItem.id);

    return (
      <Menu.Item style={{height:this.collapsed?40:"auto"}} key={keyString}>
        <NavLink className={"menu-icon__text"}  to={menuRouteItem.menuLink}>

          {MenuIcon
            ? <img src={MenuIcon} className={"anticon anticon-desktop ant-menu-item-icon"}/>
            : null}

          <span  style={{ height: this.collapsed ? "34px":"auto"}}>
          {!menuRouteItem.id.startsWith("allRequests") ||
          menuRouteItem.id == "allRequests"
            ? this.props.intl.formatMessage({ id: "menu." + (item as any).id })
            : ""}
          </span>
        </NavLink>
      </Menu.Item>
    );
  };
}

const App = injectIntl(withRouter(AppComponent));
export default App;
