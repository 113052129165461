import {FetchOptions, SerializedEntity} from "@cuba-platform/rest";
import {getCubaREST} from "@cuba-platform/react";
import {SortOrder} from "antd/lib/table/interface";
import moment from "moment";
import {AssignedGoal} from "./entities/base/tsadv$AssignedGoal";
import {ProcessInstanceData} from "./entities/base/bproc_ProcessInstanceData";
import {ExtTaskData} from "./entities/base/tsadv_ExtTaskData";
import {BprocFormData} from "./entities/bproc/bproc_FormData";
import {ProcessDefinitionData} from "./entities/base/bproc_ProcessDefinitionData";
import {BpmRolesDefiner} from "./entities/base/tsadv$BpmRolesDefiner";
import {NotPersisitBprocActors} from "./entities/base/tsadv_NotPersisitBprocActors";
import {CourseSection} from "./entities/base/tsadv$CourseSection";
import {AnsweredQuestion, AnsweredTest, TestModel} from "../app/components/Test/TestComponent";
import {Comment} from '../app/pages/Material/MaterialReviews'
import {SecurityState} from "../app/util/EntitySecurityState";
import {OrgStructureRequest} from "./entities/base/tsadv_OrgStructureRequest";
import {
  OrgRequestGrade,
  OrgRequestRow,
  OrgRequestSaveModel
} from "../app/pages/orgStructureRequest/OrgStructureRequestEdit";
import {OrganizationSaveModel} from "../app/pages/orgStructureRequest/OrganizationEditor";
import {PositionSaveModel} from "../app/pages/orgStructureRequest/PositionEditor";
import {InsuredPerson} from "./entities/base/tsadv$InsuredPerson";
import {ScheduleOffsetsRequest} from "./entities/base/tsadv_ScheduleOffsetsRequest";
import {AnsweredFeedback} from "../app/pages/MyCourse/RenderModalBody/Feedback/FeedbackQuestionAnswerComponent";
import {LearningFeedbackQuestion} from "./entities/base/tsadv$LearningFeedbackQuestion";
import {DicCompany} from "./entities/base/base_DicCompany";
import {Enrollment} from "./entities/base/tsadv$Enrollment";
import {MyTeamNew} from "./entities/base/tsadv$MyTeamNew";
import {PersonProfile} from "../app/pages/MyTeam/MyTeamCard";
import {CourseSectionAttempt} from "./entities/base/tsadv$CourseSectionAttempt";
import {EnrollmentStatus} from "./enums/enums";
import {PositionGroupExt} from "./entities/base/base$PositionGroupExt";
import {DicHrRole} from "./entities/base/tsadv$DicHrRole";
import {saveFile} from "../app/util/util";
import {Report} from "./entities/base/report$Report";
import {GanttChartVacationScheduleData} from "../app/components/VacationGanttChart";
import {VacationScheduleRequest} from "./entities/base/tsadv_VacationScheduleRequest";
import {Menu} from "../app/pages/UserSettings/UserSettingMainSection";
import {BpmUserSubstitution} from "./entities/base/tsadv$BpmUserSubstitution";
import {AssignmentSalaryPojo} from "../app/pages/Wccf/WccfEdit";
import {PositionHierarchy} from "./entities/base/tsadv_PositionHierarchy";
import {PersonGroupExt} from "./entities/base/base$PersonGroupExt";
import {OrganizationTree} from "./entities/base/tsadv$OrganizationTree";
import {OrganizationHrUser} from "./entities/base/tsadv$OrganizationHrUser";
import {PersonMedal} from "./entities/base/tsadv$PersonMedal";
import {PersonMedalRequest} from "./entities/base/tsadv_PersonMedalRequest";
import {AbstractBprocRequest} from "./entities/base/AbstractBprocRequest";
import {action, toJS} from "mobx";
import {DicAbsenceType} from "./entities/base/tsadv$DicAbsenceType";
import {DismissalRequest} from "./entities/base/tsadv_DismissalRequest";
import {HierarchyElementExt} from "./entities/base/base$HierarchyElementExt";
import {AssignmentExt} from "./entities/base/base$AssignmentExt";
import {OrganizationGroupExt} from "./entities/base/base$OrganizationGroupExt";
import {AssignmentSchedule} from "./entities/base/tsadv$AssignmentSchedule";
import {VacationPojo} from "../app/pages/VacationScheduleRequest/VacationScheduleRequestEdit";
import {EntitiesResult, QuerySettings} from "../app/components/querySettings";
import {TsadvUser} from "./entities/base/tsadv$UserExt";
import {TableDataParent} from "../app/pages/LearningBudget/Approvment/BudgetTableComponent";
import {Punishment} from "./entities/base/tsadv$Punishment";
import {BaseUserExt} from "./entities/base/base$UserExt";
import {ApproveTd} from "../app/pages/LearningRequest/ApproveTdModal";
import {KpiEmployee} from "../app/pages/AssignedGoals/CascadeBtn";
import {CascadePojo} from "../app/pages/AssignedGoals/CascadeGoal/Cascade";
import {VacancyRequest} from "./entities/base/tsadv_VacancyRequest";
import {OrganizationGroup} from "./entities/base/base$OrganizationGroup";
import {FileDescriptor} from "./entities/base/sys$FileDescriptor";
import {RequisitionDataParent} from "../app/pages/Vacancies/VacancyBrowse";
import {PositionCourse} from "./entities/base/tsadv_PositionCourse";
import {AdaptationServiceGetAssessmentResult, DocumentsFamiliarization} from "./entities/base/tsadv_AdaptationPlan";
import {PositionGroupDetails} from "./entities/base/tsadv_PositionGroupDetails";
import {JobDescriptionRequestJson} from "./entities/base/tsadv_JobDescriptionRequestJson";
import {PositionInfo} from "../app/pages/VacancyRequest/VacancyRequestEdit";
import {BirthdayDto} from "./entities/dto/BirthdayDto";
import {PositionExt} from "./entities/base/base$PositionExt";
import {TopTenPojo} from "./entities/base/tsadv$TopTenPojo";
import {RecognitionPojo} from "./entities/base/tsadv$RecognitionPojo";
import {RecognitionCommentPojo} from "./entities/base/tsadv$RecognitionCommentPojo";
import {ProfilePojo} from "./entities/base/tsadv$ProfilePojo";
import {PreferencePojo} from "./entities/base/tsadv$PreferencePojo";
import {RecognitionTypePojo} from "./entities/base/tsadv$RecognitionTypePojo";
import {PersonPoint} from "./entities/base/tsadv$PersonPoint";
import {PersonCoin} from "./entities/base/tsadv$PersonCoin";
import {AwardProgram} from "./entities/base/tsadv$AwardProgram";
import {GoodsCategoryPojo} from "./entities/base/tsadv$GoodsCategoryPojo";
import {GoodsPageInfo} from "./entities/base/tsadv$GoodsPageInfo";
import {DicDeliveryAddress} from "./entities/base/tsadv$DicDeliveryAddress";
import {GoodsOrderPageInfo} from "./entities/base/tsadv$GoodsOrderPageInfo";
import {RcgFaq} from "./entities/base/tsadv$RcgFaq";
import {CoinLogPageInfo} from "./entities/base/tsadv$CoinLogPageInfo";
import {CUBA_DISPATCHER} from "../config";
import {OrganizationPojo} from "./entities/base/tsadv$OrganizationPojo";
import {NomineePageInfo} from "./entities/base/tsadv$NomineePageInfo";
import {NomineePojo} from "./entities/base/tsadv$NomineePojo";
import {ProfilePageInfo} from "./entities/base/tsadv$ProfilePageInfo";
import { PersonMedalPojo } from "./entities/base/tsadv$PersonMedalPojo";
import {CountChartPojo} from "../app/pages/RecognitionProgram/pojo/CountChartPojo";
import {BuyChartPojo} from "../app/pages/RecognitionProgram/pojo/BuyChartPojo";
import {QualityChartPojo} from "../app/pages/RecognitionProgram/pojo/QualityChartPojo";

export const DEFAULT_DATE_PARSE_FORMAT = "YYYY-MM-DD hh:mm:ss";

export type Sort = {
  columnKey: string,
  order: SortOrder
}

export type DefaultRestParams = {
  page?: number,
  pageSize?: number,
  sort?: Sort
}

export type TelegramDto = {
  qrCode?: string,
  link?: string
}

export type TopTen = {
  order?: number,
  pId?: string,
  pgId?: string,
  fullName?: string,
  orgamization?: string,
  position?: string,
  image?: string,
  medal?: string,
  count?: number,
  birthday?: string,
  heartAward?: string,
  employeeNumber?: string
}

export type RecognitionPage = {
  pageInfo?: PageInfo,
  recognitions?: RecognitionPojo[]
}

export type PageInfo = {
  pagesCount?: number,
  totalRowsCount?: number
}

export type Recognition = {
  commentsCount?: number,
  commentPages?: number,
  createDate?: string,
  currentLike: number,
  id?: string,
  likeCount?: number,
  receiver?: RecognitionActor,
  recognitionPrivate?: boolean,
  reverseText?: string,
  say?: string,
  sender?: RecognitionActor,
  text?: string,
  translated?: boolean,
  type?: RecognitionType,

}

export type RecognitionActor = {
  employeeNumber?: string,
  id?: string,
  image?: string,
  name?: string,
  personGroupId?: string,
  personId?: string,
  position?: string,
}

export type RecognitionType = {
  code?: string,
  coins?: number,
  count?: number,
  id?: string,
  image?: string,
  name?: string,
  showEmpty?: boolean,
}

export type CourseTrainerInfo = {
  fullName?: string,
  finished?: number,
  courseCount?: number,
  image?: any,
  comments?: any[],
  information?: string,
  greeting?: string
}

export type PairModel<K, V> = {
  key: K,
  value: V
}

export type ScormInputData = {
  fieldId: string;
  answer: string;
  score: number;
  maxScore: number;
  minScore: number;
}

export type CommentHierarchy = {
  comment: RecognitionCommentPojo;
  children: RecognitionCommentPojo[];
}

export type RecognitionCommentListPojo = {
  comments: CommentHierarchy[];
  hasMore: boolean;
  hasMoreChildren: Map<string, boolean>
}

export type OrgStructureFilterParams = {
  requestId: string
} & ({ changeTypeFilter: "ALL" | "NEW" | "EDIT" | "CLOSE" } | { displayFilter: "ALL" | "CHANGES" })

type ReportExtension = "xls" | "doc" | "docx" | "xlsx" | "html" | "pdf" | "csv" | "custom";

type ReportResponse = {
  extension: ReportExtension,
  content: string
}

export type CourseCatalogModel = {
  id: string,
  langValue: string,
  courses: Array<{
    id: string,
    name: string,
    enrollmentId?: string,
    enrollmentStatus?: EnrollmentStatus,
    logo: string,
    isOnline: boolean
  }>
}
export type rating = {
  key: number
  value: number
}

export type EnrollmentCatalogModel = {
  id: string,
  langValue: string,
  courses: Array<{
    id: string,
    name: string,
    enrollmentId?: string,
    enrollmentStatus?: EnrollmentStatus,
    logo?: string,
    isOnline?: boolean,
    rating: rating[],
    commentCount?: number
  }>
}

export const restServices = {
  userMenuService: {
    getTimeZones: (params?: {}, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_UserSettingService",
        "getTimeZones",
        {...params},
        fetchOpts
      );
    },
    changePassword: (params: { oldPassword: string, newPassword: string }, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "changePassword",
        {...params},
        fetchOpts
      );
    },
  },
  notificationsService: {
    notifications: (params?: {}, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_NotificationService",
        "notifications",
        {...params},
        fetchOpts
      );
    },
    tasks: (params?: {}, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_NotificationService",
        "tasks",
        {...params},
        fetchOpts
      );
    },
    sendNotificationNextParticipant: (param:{personAssessmentId:string}):Promise<any> =>{
      return getCubaREST()!.invokeService(
        "tsadv_AssessmentParticipantAssessmentsBrowseService",
        "sendActivityAndNotificationToNextParticipant",
        {...param}
      ).then(v=>v)
    },
    closeAllNotificationsForCurrentUser: (): Promise<any> => {
      return getCubaREST()!
        .invokeService(
          "tsadv_NotificationService",
          "closeAllNotificationsForCurrentUser",
          {}
        );
    },
    closeNotificationsForCurrentUserById: (param: {
      notificationsDto: {
        notificationIds: string[]
      }
    }): Promise<any> => {
      return getCubaREST()!
        .invokeService(
          "tsadv_NotificationService",
          "closeNotificationsForCurrentUserById",
          { ...param }
        );
    },
  },
  recognitionService: {
    loadProfile(personGroupId: string): Promise<ProfilePojo> {
      return getCubaREST()!.invokeService<string>(
        "tsadv_RecognitionService",
        "loadProfile",
        {personGroupId}
      ).then(r => JSON.parse(r));
    },
    sendLike(recognitionId: string): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "sendLike",
        {recognitionId}
      )
    },
    loadComments(recognitionId: string, page: number): Promise<RecognitionCommentPojo[]> {
      return getCubaREST()!.invokeService<string>(
        'tsadv_RecognitionService',
        'loadComments',
        {recognitionId, page}
      ).then(r => JSON.parse(r));
    },
    sendComment(recognitionId: string, comment: string, parentCommentId: string|null): Promise<RecognitionCommentPojo> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "sendComment",
        {commentPojo: {recognitionId, text: comment, parentCommentId}}
      ).then((response: string) => {
        return JSON.parse(response).commentPojo;
      });
    },
    loadRecognitions:(page: number, lastCount: number, wallType: number, personGroupId: string, organizationGroupId: string, filter: string):Promise<RecognitionPage>=>{
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadRecognitions",
        {page, lastCount, wallType, personGroupId, organizationGroupId, filter}
      ).then((response: string) => {
        return JSON.parse(response);
      })
    },
    loadTopAwarded: (countOfMonths?: number): Promise<TopTen[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionRestService",
        "loadTopAwarded",
        {countOfMonths}
      ).then((response: string) => {
        return JSON.parse(response);
      })},
    loadTopSender: (countOfMonths?: number): Promise<TopTenPojo[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionRestService",
        "loadTopSender",
        {countOfMonths}
      ).then((response: string) => {
        return JSON.parse(response);
      })},
    findPersonGroupByName: (name: string): Promise<PersonGroupExt[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_PersonTsadvService",
        "findPersonGroupByName",
        {name}
      ).then((response: string) => {
        return JSON.parse(response);
      })},
    loadPersonPreferences (personGroupId: string): Promise<PreferencePojo[]> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadPersonPreferences",
        {personGroupId}
      ).then((response: string) => {
        return JSON.parse(response);
      })},
    savePersonPreference(personGroupExt: PersonGroupExt, preferences: PreferencePojo[]): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "savePersonPreference",
        {personGroupExt, preferences}
      )
    },
    loadRecognitionTypes(personGroupId: string): Promise<RecognitionTypePojo[]> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadRecognitionTypes",
        {personGroupId}
      ).then((response: string) => {
        return JSON.parse(response);
      })},
    loadPersonPoints(personGroupId: string): Promise<PersonPoint> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadPersonPoint",
        {personGroupId}
      ).then((response: string) => {
        if (response === "") {
          return {points: 0} as PersonPoint
        }
        return JSON.parse(response);
      })},
    loadPersonCoins(personGroupId: string): Promise<PersonCoin> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadPersonCoin",
        {personGroupId}
      ).then((response: string) => {
        if(response === "") {
          return {coins: 0} as PersonCoin
        }
        return JSON.parse(response);
      })},
    getActiveAwardProgram(): Promise<AwardProgram> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getActiveAwardProgram",
        {}
      ).then((response: string) => {
        return JSON.parse(response);
      })
    },
    loadCategories(): Promise<GoodsCategoryPojo[]> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadCategories",
        {}
      ).then((response: string) => {
        return JSON.parse(response);
      })
    },
    loadGoods(page: number, lastCount: number, categoryId: string, sort: string): Promise<GoodsPageInfo> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadGoods",
        {page, lastCount, categoryId, sort}
      ).then((response: string) => {
        return JSON.parse(response);
      })
    },
    addGoodsToCart(goodsId: string): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "addGoodsToCart",
        {goodsId}
      )
    },
    addToWishList(goodsId: string): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "addToWishList",
        {goodsId}
      )
    },
    loadCart(page: number, lastCount: number): Promise<GoodsPageInfo> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadCart",
        {page, lastCount}
      ).then((response: string) => {
        return JSON.parse(response);
      })
    },
    updateGoodsQuantity(goodsCartId: string, goodsId: string, quantity: number): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "updateGoodsQuantity",
        {goodsCartId, goodsId, quantity}
      )
    },
    getPersonBalance(): Promise<number> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getPersonBalance",
        {}
      ).then((resp: string) => {
        return +resp;
      })
    },
    goodsCartCount(): Promise<number>  {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "goodsCartCount",
        {}
      ).then((resp: string) => {
        return +resp;
      })
    },
    removeGoodsFromCart(goodsId: string): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "removeGoodsFromCart",
        {goodsId}
      );
    },
    getTotalSum(currentPersonGroupId: string): Promise<number> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getTotalSum",
        {currentPersonGroupId}
      ).then((resp: string) => {
        return +resp;
      })
    },
    checkoutCartList(address: DicDeliveryAddress|null): Promise<void> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "checkoutCartList",
        {address}
      )
    },
    loadGoodsOrders(page: number, lastCount: number, filter: string, dicGoodsCategory: string): Promise<GoodsOrderPageInfo> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadGoodsOrders",
        {page, lastCount, filter, dicGoodsCategory}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadRcgFaqs(): Promise<RcgFaq[]> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadRcgFaqs",
        {}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadCoinLogs(page: number, lastCount: number): Promise<CoinLogPageInfo> {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadCoinLogs",
        {page, lastCount}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    getRecognitionComments: (recognitionId: string, limit: number, offset: number):Promise<RecognitionCommentListPojo> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getRecognitionComments",
        {recognitionId, limit, offset}
      ).then((resp: string) => {
        const parse = JSON.parse(resp);
        parse.hasMoreChildren = new Map(Object.entries(parse.hasMoreChildren));
        return parse;
      })
    },
    getLikedPersons: (recognitionId: string, viewName: string):Promise<PersonGroupExt[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getLikedPersons",
        {recognitionId, viewName}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    getAllForParent: (parentCommentId: string): Promise<RecognitionCommentPojo[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "getAllForParent",
        {parentCommentId}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadOrganizations: () : Promise<OrganizationPojo[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadOrganizations",
        {}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadNomineeYears: (): Promise<number[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadMyNomineeYears",
        {}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadNomineeYearsNominations: (awarded: number): Promise<number[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadNomineeYears",
        {awarded}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadMyNominees: (page: number, lastCount: number, year: number, organizationGroupId: number): Promise<NomineePageInfo> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadMyNominees",
        {page, lastCount, year, organizationGroupId}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadTopNomineeWithDefault: (currentYear: number , organizationGroupId: string , lastYear: number, onChange: boolean): Promise<NomineePojo[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadTopNomineeWithDefault",
        {currentYear, organizationGroupId, lastYear, onChange}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadAllNomineeWithDefault: (page: number, lastCount: number, year: number, organizationGroupId: string, lastYear: number, onChange: boolean): Promise<NomineePageInfo> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadAllNomineeWithDefault",
        {page, lastCount, year, organizationGroupId, lastYear, onChange}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadProfiles: (page: number, lastCount: number): Promise<ProfilePageInfo> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadProfiles",
        {page, lastCount}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    },
    loadPersonMedals: (personGroupId: string): Promise<PersonMedalPojo[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_RecognitionService",
        "loadPersonMedals",
        {personGroupId}
      ).then((resp: string) => {
        return JSON.parse(resp);
      })
    }
  },
  learningService: {
    getCourseRatings(courseId: string, limit: number, nextOffset: number, rating: number|null): Promise<Array<Comment>> {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getCourseRatings",
        {courseId, limit, nextOffset, rating}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    learningHistory: (params: { personGroupId: string }): Promise<any[]> => {
      return getCubaREST()!.fetch(
        "GET",
        "learning-history",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    getNewNineBox: (params: { organizationGroupId: string, lang: string, appraisalProcessId: string, performancePlanId:string, idp: boolean }): any => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getNewNineBox",
        {...params},
      ).then((res: any) => {
        let value = JSON.parse(res);
        return JSON.parse(value&&value[0]&&value[0].value)
      })
    },
    getNineBox: (params: { organizationGroupId: string, lang: string, appraisalProcessId: string; ldp: boolean }): any => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getNineBox",
        {...params},
      ).then((res: any) => {
        let value = JSON.parse(res);
        return JSON.parse(value&&value[0]&&value[0].value)
      })
    },
    getPersonCourseSchedule: (params: { personGroupId: string }): Promise<any[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getPersonCourseSchedule",
        {...params}
      ).then((response:any) => {
        let value = JSON.parse(response);
        return JSON.parse(value[0].value);
      })},
    getMyAssessments:(params:{personGroupId: string, lang:string }):Promise<any[]>=>{
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getMyAssessments",
        {...params}
      ).then((r: any) => {

        return JSON.parse(r)
      })
    },
    createScheduleEnrollment: (params: { personGroupId: string, scheduleId:string, status:number, user:string }): Promise<any[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "createScheduleEnrollment",
        {...params}
      ).then((response:any) => {
        return JSON.parse(response);
      });
    },
    createCandidateForReferral: (params: { firstName: string, lastName:string, requisitionId:string  }): Promise<PersonGroupExt> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "createCandidateForReferral",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    getConcourseRequest: (param: { personGroupId: string }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getConcourseRequest",
        {...param}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    getPersonAssessments: (params: { personGroupId: string, lang: string }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getPersonAssessments",
        {...params}
      ).then((r: any) => {
        return JSON.parse(r)
      })
    },
    getPersonAssessmentForm: (params: { personGroupId: string, lang: string }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getPersonAssessmentForm",
        {...params}
      ).then((r: any) => {
        return JSON.parse(r)
      })
    },
    getOrganizationHierarchy: (params: { organizationId: any }): Promise<any> =>{
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getOrganizationHierarchy",
        {...params}
      ).then((r:any)=>{
        return  r
      })
    },
    createAssessment:(params:{personAssessmentId:string}):Promise<void> =>{
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "createAssessment",
        {...params}
      )
    },
    getPositionHierarchy:(params:{positionId: string}): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getPositionHierarchy",
        {...params}
      ).then((r: any) => {
        return r
      })
    },
    updateAssessment:(params:{assessmentId: string|null}): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_AssessmentParticipantAssessmentsBrowseService",
        "updateAssessment",
        {...params}
      ).then(r => r)
    },
    createAssessmentParticipant:(params:{personAssessmentId:string,personGroupId:string,code:string}):Promise<void> =>{
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "createAssessmentParticipant",
        {...params}
      )
    },
    getSuccessionKeyPosition: (params: {lang: string,company:string,position:string,organization:string,holder:string,manager:string,reservist:string }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getSuccessionKeyPosition",
        {...params}
      ).then((r: any) => {
        return JSON.parse(r)
      })
    },
    getSuccessionKeyPositionInfo: (params: {lang: string,positionGroupId:string }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getSuccessionKeyPositionInfo",
        {...params}
      ).then((r: any) => {
        return JSON.parse(r)
      })
    },
    createJobRequestForRecommendation:(params:{candidatePersonGroup:string,requisition:string,requestDate:string}) => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "createJobRequestForRecommendation",
        {...params}
      )
    },
    getStudentRating: (params: {lang: string,company:string,searchText:string,type:string|null,year:number,month:number|null,limit:number,nextOffset:number }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_LearningService",
        "getStudentRating",
        {...params}
      ).then((r: any) => {
        return JSON.parse(r)
      })
    },
  },
  courseService: {
    courseInfo: (params: { courseId: string, personGroupId: string }): Promise<CourseInfo> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "courseInfo",
        {...params}
      ).then((response: string) => {
        const courseInfo: CourseInfo = JSON.parse(response) as CourseInfo;
        courseInfo.startDate = moment(courseInfo.startDate, DEFAULT_DATE_PARSE_FORMAT);
        courseInfo.endDate = moment(courseInfo.endDate, DEFAULT_DATE_PARSE_FORMAT);

        return courseInfo;
      });
    },
    courseTrainerInfo: (params: { trainerId: string }): Promise<CourseTrainerInfo> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "courseTrainerInfo",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    searchCourses: (params: { personGroupId: string, courseName: string }): Promise<Array<CourseCatalogModel>> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "searchCourses",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    courseEnrollmentInfo: (params: { enrollmentId: string }): Promise<Enrollment> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "courseEnrollmentInfo",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    allCourses: (params: { personGroupId: string }): Promise<Array<CourseCatalogModel>> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "allCourses",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    courseSectionWithEnrollmentAttempts: (params: { courseSectionId: string, enrollmentId: string }): Promise<CourseSection> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "courseSectionWithEnrollmentAttempts",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    createScormAttempt: (params: { courseSectionId: string, enrollmentId: string, inputData: ScormInputData[], success: boolean }): Promise<void> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "createScormAttempt",
        {...params}
      );
    },
    createTestScormAttempt: (params: { courseSectionId: string, enrollmentId: string, score: number, maxScore: number, minScore: number }): Promise<CourseSectionAttempt> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "createTestScormAttempt",
        {...params}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    validateEnroll: (params: { courseId: string, personGroupId: string, locale: string }): Promise<PairModel<boolean, string[]>> => {
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "validateEnroll",
        {...params}
      ).then((response: string) => JSON.parse(response));
    },
    getCourseSubCategoriesWithCourseSearchAndPagination:(params:{personGroupId:string,searchString:string,offset:number, limit:number|null}):Promise<any>=>{
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "getCourseSubCategoriesWithCourseSearchAndPagination",
        {...params}
      ).then((response:any)=>JSON.parse(response))
    },
    getSubCategoryCoursesWithSearchAndPagination:(params:{subCategoryId:string,personGroupId:string,searchString:string|null,offset:number, limit:number}):Promise<any>=>{
      return getCubaREST()!.invokeService(
        "tsadv_CourseService",
        "getSubCategoryCoursesWithSearchAndPagination",
        {...params}
      ).then((response:any)=>JSON.parse(response))
    }
  },
  userService: {
    setWhatsAppForCurrentUser:(number: string): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_UserService",
        "setWhatsAppForCurrentUser",
        {number}
      )
    }
  },
  kpiService: {
    kpiAssignedGoals: (params: { appId: string }): Promise<PairModel<string, SerializedEntity<AssignedGoal>[]>[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_KpiService",
        "kpiAssignedGoals",
        {...params}
      ).then((response: string) => JSON.parse(response));
    },
    getKpiSubordinateEmployees: (params: { goalId: string }): Promise<SerializedEntity<KpiEmployee>[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_KpiService",
        "getKpiSubordinateEmployees",
        {...params}
      ).then((response: string) => JSON.parse(response));
    },
    cascadeAssignedGoal: (params: { goalId: string, cascadePojos: CascadePojo[] }): Promise<void> => {
      return getCubaREST()!.invokeService(
        "tsadv_KpiService",
        "cascadeAssignedGoal",
        {...params}
      );
    },
  },
  enrollmentService: {
    searchEnrollments: (params: { personGroupId: string, courseName?: string }): Promise<SerializedEntity<EnrollmentCatalogModel>[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_EnrollmentService",
        "searchEnrollment",
        {...params}
      ).then((response: string) => JSON.parse(response));
    }
  },
  telegramService: {
    registerUserByTelegramCode(tempCode: string): Promise<void> {
      return getCubaREST()!.invokeService(
        "base_TelegramRegisterService",
        "registerUserByTelegramCode",
        {tempCode}
      ).then((response: string) => {
        if (response !== "true") {
          throw new Error("Wrong code");
        }
      });
    },
    getTelegramLinkForCurrentUser(): Promise<TelegramDto> {
      return getCubaREST()!.invokeService(
        "base_TelegramRegisterService",
        "getTelegramLinkForCurrentUser",
        {}
      ).then((response: string) => JSON.parse(response));
    },
  },
  lmsService: {
    loadCourseSectionData: (params: { enrollmentId: string, courseSectionId: string }, fetchOpts?: FetchOptions): Promise<SerializedEntity<CourseSection>> => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "loadCourseSectionData",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    startAndLoadTest: (params: { courseSectionObjectId: string, enrollmentId: string }, fetchOpts?: FetchOptions): Promise<TestModel> => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "startAndLoadTest",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    finishTest: (params: { answeredTest: AnsweredTest }, fetchOpts?: FetchOptions): Promise<{
      score: number,
      maxScore: number,
      success: boolean
    }> => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "finishTest",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    finishFeedback: (params: { answeredFeedback: AnsweredFeedback, personGroupId: string }, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "finishFeedback",
        {...params},
        fetchOpts
      );
    },
    loadFeedbackData: (params: { feedbackTemplateId: string }, fetchOpts?: FetchOptions): Promise<LearningFeedbackQuestion[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_LmsService",
        "loadFeedbackData",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    }
  },
  portalHelperService: {
    newEntity: <T>(param ?: { entityName: string }): Promise<T> => {
      return getCubaREST()!.invokeService(
        "tsadv_PortalHelperService",
        "newEntity",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    companiesForLoadDictionary: (param: { personGroupId: string }): Promise<string[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_PortalHelperService",
        "getCompaniesForLoadDictionary",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    initPortalMenu: (param: { menuList: Menu[] }): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_PortalHelperService",
        "initPortalMenu",
        {...param}
      );
    },
    getConfig: (param: { classFQN: string, methodName: string }, fetchOpts?: FetchOptions): Promise<string> => {
      return getCubaREST()!.invokeService(
        "tsadv_PortalHelperService",
        "getConfig",
        {...param},
        fetchOpts
      ).then((response: string) => response);
    },
  },
  bprocService: {
    tasks: (param: { processInstanceData: ProcessInstanceData }): Promise<Array<SerializedEntity<ExtTaskData>>> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "getProcessTasks",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    getActiveTask: (param: { processInstanceData: ProcessInstanceData }): Promise<ExtTaskData> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "getActiveTask",
        {...param}
      ).then((value: string) => {
        return JSON.parse(value);
      });
    },
    isActiveTask: (param: { taskData: ExtTaskData }): Promise<boolean> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "isActiveTask",
        {...param}
      ).then((value: string) => {
        return JSON.parse(value);
      });
    },
    processInstanceData: (param: { processInstanceBusinessKey: string, processDefinitionKey: string }): Promise<ProcessInstanceData> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "getProcessInstanceData",
        {...param}
      ).then((value: string) => {
        if (value) return JSON.parse(value);
        return value;
      });
    },
    getProcessDefinitionData: (param: { processDefinitionKey: string }): Promise<ProcessDefinitionData> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "getProcessDefinitionData",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    getStartFormData: (param: { processDefinitionKey: string }): Promise<BprocFormData> => {
      return getCubaREST()!.invokeService(
        "tsadv_BprocService",
        "getStartFormData",
        {...param}
      ).then((value: string) => JSON.parse(value));
    }
  },
  bprocFormService: {
    getStartFormData: (param: { processDefinitionId: string }): Promise<BprocFormData> => {
      return getCubaREST()!.invokeService(
        "bproc_BprocFormService",
        "getStartFormData",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    getTaskFormData: (param: { taskId: string }): Promise<BprocFormData> => {
      return getCubaREST()!.invokeService(
        "bproc_BprocFormService",
        "getTaskFormData",
        {...param}
      ).then((value: string) => JSON.parse(value));
    }
  },
  startBprocService: {
    getBpmRolesDefiner: (param: { request: AbstractBprocRequest, employeePersonGroupId: string, isAssistant: boolean }): Promise<BpmRolesDefiner> => {
      return getCubaREST()!.invokeService(
        "tsadv_StartBprocService",
        "getBpmRolesDefiner",
        {...param}
      ).then((value: string) => JSON.parse(value));
    },
    getNotPersisitBprocActors: (param: {
      employeePersonGroupId: string,
      bpmRolesDefiner: BpmRolesDefiner,
      isAssistant: boolean,
      request: AbstractBprocRequest
    }): Promise<Array<SerializedEntity<NotPersisitBprocActors>>> => {
      return getCubaREST()!.invokeService(
        "tsadv_StartBprocService",
        "getNotPersisitBprocActors",
        {...param, request: toJS(param.request)}
      ).then((value: string) => {
        return JSON.parse(value);
      })
    },
    saveBprocActors: (param: { entityId: string, notPersisitBprocActors: Array<NotPersisitBprocActors> }): Promise<void> => {
      return getCubaREST()!.invokeService(
        "tsadv_StartBprocService",
        "saveBprocActors",
        {...param}
      );
    }
  },
  bprocRuntimeService: {
    startProcessInstanceByKey: (param: { processDefinitionKey: string, businessKey: string, variables: any }): Promise<void> => {
      return getCubaREST()!.invokeService(
        "bproc_BprocRuntimeService",
        "startProcessInstanceByKey",
        {...param}
      );
    }
  },
  bprocTaskService: {
    completeWithOutcome: (param: { taskData: ExtTaskData, outcomeId: string, processVariables: any }): Promise<void> => {
      return getCubaREST()!.invokeService(
        "bproc_BprocTaskService",
        "completeWithOutcome",
        {...param}
      );
    }
  },
  fileDownload: {
    download: (fileId: string) => {
      return getCubaREST()!.getFile(fileId)
        .then(value => value);
    }
  },
  portalAccessEntityAttributesService: {
    entityAttributesSecurityState: (param: { entityName: string, entityId: string }): Promise<SecurityState> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PortalAccessEntityAttributesService",
        "entityAttributesSecurityState",
        {...param}
      ).then(r => JSON.parse(r));
    }
  },
  absenceService: {
    getLaborLeave: (view = '_local'): Promise<DicAbsenceType> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AbsenceService",
        "getLaborLeave",
        {view: view}
      ).then(value => JSON.parse(value));
    },
    vacationDurationType: (param: { personGroupId: string, absenceTypeId: string, dateFrom: Date | null }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AbsenceService",
        "getVacationDurationType",
        {...param}
      );
    },
    countDays: (param: { dateFrom: Date, dateTo: Date, absenceTypeId: string, personGroupId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AbsenceService",
        "countDays",
        {...param}
      );
    },
    countAbsenceHours: (param: { dateFrom: Date, dateTo: Date, absenceTypeId: string, personGroupId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_AbsenceService",
        "countAbsenceHours",
        {...param}
      );
    },
    getReceivedVacationDaysOfYear: (param: { date: Date, absenceTypeId: string, personGroupId: string }): Promise<number> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_AbsenceService",
        "getReceivedVacationDaysOfYear",
        {...param}
      );
    },
    getRemainingDaysWeekendWork: (personGroupId: string): Promise<number> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AbsenceService",
        "getRemainingDaysWeekendWork",
        {personGroupId: personGroupId}
      ).then(value => parseInt(value));
    },
    countDaysWithoutHolidays: (param: { dateFrom: Date, dateTo: Date, personGroupId: string }): Promise<number> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AbsenceService",
        "countDaysWithoutHolidays",
        {...param}
      ).then(value => parseInt(value));
    },
    scheduleOffsetDaysBeforeAbsence: (): Promise<number | undefined> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_AbsenceService",
        "scheduleOffsetDaysBeforeAbsence",
        {}
      ).then(response => {
        if (!response) {
          return undefined
        }
        return response;
      });
    },
  },
  absenceRvdService: {
    countTotalHours: (param: { dateFrom: any, dateTo: any, absenceTypeId: string, personGroupId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_AbsenceRvdService",
        "countTotalHours",
        {...param}
      );
    }
  },
  absenceBalanceService: {
    getAbsenceBalance: (param: { absenceTypeId?: string, absenceDate: any, personGroupId: string }): Promise<number> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_AbsenceBalanceService",
        "getAbsenceBalance",
        {...param}
      ).then(value => value ? (Math.round(value * 100) / 100) : value);
    },
    getNewPersonBalance: (param: { personGroupId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<any>(
        "tsadv_AbsenceBalanceService",
        "getNewPersonBalance",
        {...param}
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
  },
  documentService: {
    getInsuredPerson: (params: { type: string }, fetchOpts?: FetchOptions): Promise<InsuredPerson> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "getInsuredPerson",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    getInsuredPersonMembers: (params: { insuredPersonId: any }, fetchOpts?: FetchOptions): Promise<Array<InsuredPerson>> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "getInsuredPersonMembers",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    getInsuredPersonMembersWithNewContract: (params: { insuredPersonId: string, contractId: string }, fetchOpts?: FetchOptions): Promise<Array<InsuredPerson>> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "getInsuredPersonMembersWithNewContract",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    checkPersonInsure: (params: { personGroupId: any, contractId: any }, fetchOpts?: FetchOptions): Promise<Boolean> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "checkPersonInsure",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    calcAmount: (params: { insuranceContractId: any, personGroupExtId: any, relativeTypeId: any, bith: any }, fetchOpts?: FetchOptions): Promise<number> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "calcAmount",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    getMyInsuraces: (fetchOpts?: FetchOptions): Promise<Array<InsuredPerson>> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "getMyInsuraces",
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },

    getOffsetRequestsNew: (params: {}, fetchOpts?: FetchOptions): Promise<ScheduleOffsetsRequest> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "getOffsetRequestsNew",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    calcTotalAmount: (params: { insuredPersonId: any }, fetchOpts?: FetchOptions): Promise<number> => {
      return getCubaREST()!.invokeService(
        "tsadv_DocumentService",
        "calcTotalAmount",
        {...params},
        fetchOpts
      ).then((response: string) => JSON.parse(response));
    },
    commitFromPortal: (insuredPerson: InsuredPerson): Promise<InsuredPerson> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_DocumentService",
        "commitFromPortal",
        {insuredPerson: insuredPerson}
      ).then((response: string) => JSON.parse(response));
    }
  },
  employeeService: {
    getUserExtByPersonGroupId: (personGroupId: string): Promise<TsadvUser> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getUserExtByPersonGroupId",
        {personGroupId}
      ).then(r => JSON.parse(r));
    },
    getPersonImage: (personGroupId: string): Promise<string> => {
      return fetch(`${CUBA_DISPATCHER}person_image/${personGroupId}`)
        .then(async response => {
          if (!response.ok) {
            return "";
          }

          const contentType = response.headers.get("Content-Type");
          return `data:${contentType};base64, ` + await response.text()
        })
    },
    getOrganizationGroupByPersonGroupId(personGroupId: string, viewName: string): Promise<OrganizationGroupExt> {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getOrganizationGroupByPersonGroupId",
        {personGroupId, viewName}
      ).then(r => JSON.parse(r));
    },
    personProfile: (personGroupId: string): Promise<PersonProfile> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "personProfile",
        {personGroupId: personGroupId}
      ).then(r => JSON.parse(r));
    },
    personGroupInfo: (userId: string): Promise<PersonProfile> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "personGroupInfo",
        {userId: userId}
      ).then(r => JSON.parse(r));
    },
    getCompanyByPersonGroupId: (param: { personGroupId: string }): Promise<DicCompany> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getCompanyByPersonGroupId",
        {...param}
      ).then(r => JSON.parse(r));
    },
    hasHrRole: (param: { dicHrCode: string }): Promise<boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "hasHrRole",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getEmployees: (param: { date: string, view: string }): Promise<SerializedEntity<Array<PersonGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getEmployees",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getBirthdayPeopleTodayList: (param: { company: string }): Promise<PersonGroupExt[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getBirthdayPeopleTodayList",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getBirthdayPeopleForCurrentMonthList: (): Promise<BirthdayDto[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getBirthdayPeopleForCurrentMonthList",
        {}
      ).then(r => JSON.parse(r));
    },
    getHrUsers: (param: { organizationGroupId: string, roleCodes: string | null }): Promise<OrganizationHrUser[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getHrUsers",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getManagersByPersonGroup: (personGroupId:string): Promise<TsadvUser[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getManagersByPersonGroup",
        {personGroupId:personGroupId}
      ).then(r => JSON.parse(r));
    },
    getEmployeeWithOrganizationAndSearch: ( param:{organization:string|null, lang:string, search:string|null } ):Promise<any>=>{
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getEmployeeWithOrganizationAndSearch",
        {...param}
      ).then((response: string | null | undefined) => {
        if (response) {
          let value = JSON.parse(response);
          if (value && value[0]) {
            return JSON.parse(value[0].value);
          }
        }
      });
    },
    getPersonExperienceJson: ( param:{pPersonGroupId:string, pLang:string, pDate:string } ):Promise<any>=>{
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeService",
        "getPersonExperienceJson",
        {...param}
      ).then((r:any) => {
        return  JSON.parse(r);

      });
    },
  },
  requisitionService: {
    getRequisitionTreeData: (): Promise<Array<RequisitionDataParent>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_RequisitionService",
        "getRequisitionTreeData",
        {}
      ).then(r => JSON.parse(r));
    },
  },
  successionPlanningService:{
    getSuccessionPlannings:(param:{successionPlanningList:any, view:string,company?:string,position?:string,organization?:string,successor?:string}):Promise<any>=>{
      return getCubaREST()!.invokeService<any>(
        "tsadv_SuccessionPlanningService",
        "getSuccessionPlannings",
        {...param}
      ).then(r=>JSON.parse(r))
    }
  },

  orgStructureService: {
    initialCreate: (personGroupId: string): Promise<OrgStructureRequest> => {
      return getCubaREST()!
        .invokeService("tsadv_OrgStructureRequestService", "initialCreate", {personGroupId})
        .then((value: string) => JSON.parse(value));
    },
    getMergedOrgStructure: (param: { requestId: string }): Promise<Array<OrgRequestRow>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "getMergedOrgStructure",
        {...param}
      ).then(r => JSON.parse(r));
    },
    saveRequest: (param: { orgRequestSaveModel: OrgRequestSaveModel }): Promise<OrgStructureRequest> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "saveRequest",
        {...param}
      ).then(r => JSON.parse(r));
    },
    saveOrganization: (param: { organizationRequestSaveModel: OrganizationSaveModel }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "saveOrganization",
        {...param}
      );
    },
    savePosition: (param: { positionRequestSaveModel: PositionSaveModel }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "savePosition",
        {...param}
      );
    },
    exclude: (param: { requestId: string, requestDetailId: string, elementGroupId: string, elementType: string }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "exclude",
        {...param}
      );
    },
    excludeData: (param: { requestId: string, data: OrgRequestRow }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "exclude",
        {...param}
      );
    },
    getGrades: (): Promise<Array<OrgRequestGrade>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "getGrades",
        null
      ).then(r => JSON.parse(r));
    },
    getMergedOrgStructureFilter: (param: OrgStructureFilterParams): Promise<Array<OrgRequestRow>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "getMergedOrgStructure",
        {...param}
      ).then(r => JSON.parse(r));
    },
    availableSalary: (personGroupId: string): Promise<boolean> => {
      return getCubaREST()!
        .invokeService<string>(
          "tsadv_OrgStructureRequestService",
          "availableSalary",
          { personGroupId }
        )
        .then(r => JSON.parse(r));
    },
    hasPermitToCreate: (param?: {}): Promise<boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrgStructureRequestService",
        "hasPermitToCreate",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getUserHasAccessToCreateOrgStructureRequest: (): Promise<string> => {
      return getCubaREST()!
        .invokeService<string>(
          "tsadv_OrgStructureRequestService",
          "getUserHasAccessToCreateOrgStructureRequest",
          {}
        )
    }
  },
  myTeamService: {
    getChildren: (param: { parentPositionGroupId: string, parent?: MyTeamNew }): Promise<Array<MyTeamNew>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_MyTeamService",
        "getChildren",
        {...param}
      ).then(r => JSON.parse(r));
    },
    searchMyTeam: (param: { parentPositionGroupId: string, searchFio: string }): Promise<Array<MyTeamNew>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_MyTeamService",
        "searchMyTeam",
        {...param}
      ).then(r => JSON.parse(r));
    },
  },
  hierarchyService: {
    getOrganizationChildHierarchyElement: (param: { parentId: string | null, personGroupId: string | null }): Promise<Array<HierarchyElementExt>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_HierarchyService",
        "getOrganizationChildHierarchyElement",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getHeadersByBudget: (param: { personGroupId: string | null, budgetId: string }): Promise<Array<SerializedEntity<HierarchyElementExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_HierarchyService",
        "getHeadersByBudget",
        {...param}
      ).then(r => JSON.parse(r));
    },
  },
  assignmentService: {
    getPersonGroupsByListOfOrganization: (param: { organizationGroupExtList: SerializedEntity<OrganizationGroupExt>[] }): Promise<Array<SerializedEntity<PersonGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AssignmentService",
        "getPersonGroupsByListOfOrganization",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getAssignmentsByListOfOrganization: (param: { organizationGroupExtList: SerializedEntity<OrganizationGroupExt>[] }): Promise<Array<SerializedEntity<AssignmentExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AssignmentService",
        "getAssignmentsByListOfOrganization",
        {...param}
      ).then(r => JSON.parse(r));
    },
  },
  learningBudgetService: {
    sendNotifyAboutSendForRevision: (param: { userId: string, responsibleUserId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutSendForRevision",
        {...param}
      );
    },
    sendNotifyAboutSendForRevisionForecast: (param: { userId: string, responsibleUserId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutSendForRevisionForecast",
        {...param}
      );
    },
    sendNotifyAboutApproveBudget: (param: { userId: string, responsibleUserId: string, learningBudgetHeaderId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutApproveBudget",
        {...param}
      );
    },
    sendNotifyAboutApproveBudgetForecast: (param: { userId: string, responsibleUserId: string, learningBudgetHeaderId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutApproveBudgetForecast",
        {...param}
      );
    },
    sendNotifyAboutDelegatingBudget: (param: { userId: string, responsibleUserId: string, learningBudgetHeaderId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutDelegatingBudget",
        {...param}
      );
    },
    sendNotifyAboutDelegatingBudgetForecast: (param: { userId: string, responsibleUserId: string, learningBudgetHeaderId: string, url: string }) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "sendNotifyAboutDelegatingBudgetForecast",
        {...param}
      );
    },
    createBudgetDetailReport: (param: { learningBudgetId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<any>(
        "tsadv_LearningBudgetService",
        "createBudgetDetailReport",
        {...param}
      );
    },
    findReportGroupByOrganizationGroupId: (param: { organizationGroupId: string }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_LearningBudgetService",
        "findReportGroupByOrganizationGroupId",
        {...param}
      );
    },
  },
  organizationHrUserService: {
    isManagerOrSupManager: (param: { userId: string, employeePersonGroupId: string }): Promise<boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrganizationHrUserService",
        "isManagerOrSupManager",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getDicHrRoles: (params: { userId: string }): Promise<DicHrRole[]> => {
      return getCubaREST()!.invokeService(
        "tsadv_OrganizationHrUserService",
        "getDicHrRoles",
        {
          ...params
        }
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
    getHrUsers: (param: { organizationGroupId: string, roleCodes: string | null, counter: number | null }): Promise<OrganizationHrUser[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrganizationHrUserService",
        "getHrUsers",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getHrUsersForPerson: (param: { personGroupId: string, roleCode: string | null }): Promise<TsadvUser[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrganizationHrUserService",
        "getHrUsersForPerson",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getManagerByOrganizationGroupId: (param: { organizationGroupId: string, roleCode: string }): Promise<SerializedEntity<PersonGroupExt>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrganizationHrUserService",
        "getManagerByOrganizationGroupId",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getOrganizationList: (params: { userId: string, roleCode: string, date: string }): Promise<Array<OrganizationGroupExt>> => {
      return getCubaREST()!.invokeService(
        "tsadv_OrganizationHrUserService",
        "getOrganizationList",
        {
          ...params
        }
      ).then((response: string) => {
        return JSON.parse(response);
      });
    },
  },
  userSettingService: {
    saveSetting: (name: string, value: string): void => {
      getCubaREST()!.invokeService<string>(
        "cuba_UserSettingService",
        "saveSetting",
        {
          clientType: 'P',
          name: name,
          value: value
        }
      );
    },
    loadSetting: <T>(name: string): Promise<T> => {
      return getCubaREST()!.invokeService<string>(
        "cuba_UserSettingService",
        "loadSetting",
        {
          clientType: 'P',
          name: name
        }
      ).then(value => JSON.parse(value));
    },
  },
  assignedGoalService: {
    getAssignedGoalFullHierarchy: (params: { assignedGoalId: string }): Promise<Array<AssignedGoal>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AssignedGoalService",
        "getAssignedGoalFullHierarchy",
        params
      ).then(value => JSON.parse(value));
    },
  },
  positionService: {
    getPosition: (userId: string, view: string|null): Promise<PositionExt> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionService",
        "getPosition",
        {userId, view}
      ).then(value => JSON.parse(value));
    },
    getManager: (positionGroupId: string): Promise<PositionGroupExt> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionService",
        "getManager",
        {positionGroupId: positionGroupId}
      ).then(value => JSON.parse(value));
    },
    getPositionInfo: (positionGroupId: string, date: string): Promise<PositionInfo> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionService",
        "getPositionInfo",
        {positionGroupId: positionGroupId, date: date}
      ).then(value => JSON.parse(value));
    },
    getChildPositions: (date: string, view: string): Promise<PositionGroupExt> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionService",
        "getChildPositions",
        {date: date, view: view}
      ).then(value => JSON.parse(value));
    },
    getActiveChildPositions: (date: string, view: string): Promise<PositionGroupExt[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionService",
        "getActiveChildPositions",
        {date: date, view: view}
      ).then(value => JSON.parse(value));
    }
  },
  employeeNumberService: {
    generateNextRequestNumber: (): Promise<Number> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeNumberService",
        "generateNextRequestNumber",
        {}
      ).then(value => JSON.parse(value));
    },
  },
  executiveAssistantService: {
    getManagerList: (assistantPositionGroupId: string): Promise<PersonProfile[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_ExecutiveAssistantService",
        "getManagerList",
        {
          assistantPositionGroupId: assistantPositionGroupId
        }
      ).then(value => JSON.parse(value));
    },
  },
  commonReportsService: {
    downloadReportByCode: (params: { reportCode: string, entityId: string, entityParamName: string }): Promise<ReportResponse> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_CommonReportsService",
        "downloadReportByCode",
        params).then(response => JSON.parse(response));
    }
  },
  reports: {
    loadReportByCode: (reportCode: string): Promise<Report> => {
      return getCubaREST()!.searchEntities<Report>(Report.NAME, {
        conditions: [{
          property: 'code',
          operator: '=',
          value: reportCode
        }, {
          property: 'restAccess',
          operator: '=',
          value: 'TRUE'
        }]
      }, {
        limit: 1
      })
        .then(value => {
          if (value && value.length === 1) return value[0];
          throw new Error("report[" + reportCode + "] not found!");
        });
    },
    run: async (reportId: string, data: any, catchException?: (reason: any) => void) => {
      return await fetch(getCubaREST()!.apiUrl + `reports/v1/run/${reportId}`,
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + getCubaREST()!.restApiToken
          },
          method: 'POST',
          body: JSON.stringify(data)
        }).then(value => {
        if (value.ok) {
          const contentDisposition = decodeURIComponent(value.headers.get('Content-Disposition') || "");
          const fileName = contentDisposition.split("\"")[1];
          value.blob().then(blobUrl => saveFile(blobUrl, fileName));
        }
        return value;
      }).catch(reason => {
        if (catchException) catchException(reason);
      });
    },
    runFetch: async (reportId: string, data: any) => {
      return await fetch(getCubaREST()!.apiUrl + `reports/v1/run/${reportId}`,
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + getCubaREST()!.restApiToken
          },
          method: 'POST',
          body: JSON.stringify(data)
        }).then(value => {
        if (value.ok) {
          const contentDisposition = decodeURIComponent(value.headers.get('Content-Disposition') || "");
          const fileName = contentDisposition.split("\"")[1];
          value.blob().then(blobUrl => saveFile(blobUrl, fileName));
          return value;
        }
        throw Error()
      })
    },
  },
  vacationScheduleRequestService: {
    approveVacationRequest: (params: { vacations: string[] }): Promise<void> => {
      return getCubaREST()!.invokeService<void>(
        "tsadv_VacationScheduleRequestService",
        "approveVacationRequest",
        params
      );
    },
    getVacationScheduleBalanceDays: (params: { vacation: VacationPojo }): Promise<number> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "getVacationScheduleBalanceDays",
        params
      ).then(value => JSON.parse(value));
    },
    ganttChart: (startDate: string, endDate: string): Promise<Array<GanttChartVacationScheduleData>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "ganttChart",
        {
          startDate: startDate,
          endDate: endDate,
        }
      ).then(value => JSON.parse(value));
    },
    getChildVacationSchedule: (querySettings: QuerySettings): Promise<EntitiesResult<VacationScheduleRequest>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "getChildVacationSchedule", {
          querySettings: querySettings
        })
        .then(value => {
          const parse = JSON.parse(value);
          return {
            entities: JSON.parse(parse.entities),
            count: parse.count
          }
        });
    },
    getPositionChildVacationSchedule: (querySettings: QuerySettings, positionGroupId: string): Promise<EntitiesResult<VacationScheduleRequest>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "getChildVacationSchedule", {
          querySettings: querySettings,
          positionGroupId: positionGroupId
        })
        .then(value => {
          const parse = JSON.parse(value);
          return {
            entities: JSON.parse(parse.entities),
            count: parse.count
          }
        });
    },
    filterEmployees: (param: { positionGroupId: string, date: string, view: string, isAssistant: boolean }): Promise<Array<SerializedEntity<PersonGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "filterEmployees",
        {...param}
      ).then(r => JSON.parse(r));
    },
    filterPositions: (param: { positionGroupId: string, date: string, view: string, isAssistant: boolean }): Promise<Array<SerializedEntity<PositionGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "filterPositions",
        {...param}
      ).then(r => JSON.parse(r));
    },
    filterOrganizations: (param: { positionGroupId: string, date: string, view: string, isAssistant: boolean }): Promise<Array<SerializedEntity<OrganizationGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacationScheduleRequestService",
        "filterOrganizations",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getPositionHierarchyPath:(param:{positionGroupId:any, lang?:any|null, date?:any|null}):Promise<any>=>{
      return getCubaREST()!.invokeService<any>(
        "tsadv_PositionService",
        "getPositionHierarchyPath",
        {...param}
      ).then(r=>r)
    }
  },
  bpmUserSubstitutionService: {
    save: (bpmUserSubstitution: BpmUserSubstitution): Promise<boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_BpmUserSubstitutionService",
        "save",
        {
          bpmUserSubstitution: bpmUserSubstitution
        }
      ).then(value => JSON.parse(value));
    },
  },
  incentiveService: {
    getIncentiveList: (pagination: QuerySettings): Promise<EntitiesResult<GanttChartVacationScheduleData>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_IncentiveService",
        "getIncentiveList",
        {
          querySettings: pagination
        }
      ).then(value => {
        const parse = JSON.parse(value);
        return {
          entities: JSON.parse(parse.entities),
          count: parse.count
        }
      });
    },
    saveMonthResult: (status: string, comment: string, incentiveMonthResultId: string) => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_IncentiveService",
        "saveMonthResult",
        {
          status: status,
          comment: comment,
          incentiveMonthResultId: incentiveMonthResultId
        }
      );
    }
  },
  assignmentSalaryRequestService: {
    getAssignmentSalaryInfo: async (params: { date: any, personGroupId: string }): Promise<AssignmentSalaryPojo> => {
      return await getCubaREST()!.invokeService<string>(
        "tsadv_AssignmentSalaryRequestService",
        "getAssignmentSalaryInfo",
        params)
        .then(value => JSON.parse(value));
    },
    getMyOrgAndChilds: (): Promise<Array<OrganizationGroup>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AssignmentSalaryRequestService",
        "getMyOrgAndChilds",
        {}
      ).then(r => JSON.parse(r))
    },


    //   (): Promise<OrganizationGroup> => {
    //   return getCubaREST()!.invokeService<string>(
    //     "tsadv_AssignmentSalaryRequestService",
    //     "getMyOrgAndChilds", {}
    //   ).then(r => JSON.parse(r));
    // },
  },
  positionStructureService: {
    getChildren: (param: { parentId: string }): Promise<Array<PositionHierarchy>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getChildren",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getStartData: (param: {}): Promise<Array<PositionHierarchy>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getStartData",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getPositionDetails: (param: { positionGroupId: string }): Promise<PositionGroupDetails> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getPositionDetails",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getOrganizationHierarchyTree: (param: { organizationGroupId: string }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getOrganizationHierarchyTree",
        {...param}
      ).then(r => JSON.parse(r));
    }
  },
  successorService: {
    getSuccessorsByPosition: async (param: { positionGroupId: string, date: string }): Promise<Array<PositionHierarchy>> => {
      return await getCubaREST()!.invokeService<string>(
        "tsadv_SuccessorService",
        "getSuccessorsByPosition",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getPositionDetails: (param: { positionGroupId: string }): Promise<PositionGroupDetails> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getPositionDetails",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getOrganizationHierarchyTree: (param: { organizationGroupId: string }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PositionStructureService",
        "getOrganizationHierarchyTree",
        {...param}
      ).then(r => JSON.parse(r));
    },
  },
  jobDescriptionService: {
    getJobDescriptionRequests: (param: { positionId: string }): Promise<Array<JobDescriptionRequestJson>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_JobDescriptionService",
        "getJobDescriptionRequests",
        {...param}
      ).then(r => JSON.parse(r));
    }
  },
  dismissalService: {
    saveUserFeedback: (params: { templateId: string, personGroupId: string, questionsAndAnswers: AnsweredQuestion[] }, fetchOpts?: FetchOptions) => {
      return getCubaREST()!.invokeService(
        "tsadv_DismissalService",
        "saveUserFeedback",
        {...params},
        fetchOpts
      );
    },
    getDismissalRequest: (params: {personGroupId: string}): Promise<DismissalRequest> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_DismissalService",
        "getDismissalRequest",
        {...params}
      ).then(r => JSON.parse(r));
    },
    existExitInterview: (params: { personGroupId: string }): Promise<string> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_DismissalService",
        "existExitInterview",
        {...params}
      ).then((r: string) => r);
    },
  },
  assignmentScheduleService: {
    getAssignmentSchedule: (param: { personGroupId: string, date: string, view: string }): Promise<SerializedEntity<AssignmentSchedule>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AssignmentScheduleService",
        "getAssignmentSchedule",
        {...param}
      ).then(r => JSON.parse(r));
    },
  },
  employeeHierarchyService: {
    getChildEmployees: (param: { positionGroupId: string, date: string, view: string }): Promise<Array<SerializedEntity<PersonGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeHierarchyService",
        "getChildEmployees",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getChildPositions: (param: { positionGroupId: string, date: string, view: string }): Promise<Array<SerializedEntity<PositionGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeHierarchyService",
        "getChildPositions",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getChildPositionOrganizations: (param: { positionGroupId: string, date: string, view: string }): Promise<Array<SerializedEntity<OrganizationGroupExt>>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_EmployeeHierarchyService",
        "getChildPositionOrganizations",
        {...param}
      ).then(r => JSON.parse(r));
    },
    getPositionHierarchyPath:(positionGroupId: string, lang?:string ,date?: any):Promise<any>=>{
      return  getCubaREST()!.invokeService<any>(
        "tsadv_PositionService",
        "getPositionHierarchyPath",
        positionGroupId
      ).then(r =>JSON.parse(r));
    }
  },
  positionHierarchyService:{
    getPositionHierarchyPath:(param:{positionGroupId:any, lang?:any|null, date?:any|null}):Promise<any>=>{
      return getCubaREST()!.invokeService<any>(
        "tsadv_PositionService",
        "getPositionHierarchyPath",
        {...param}
      ).then(r=>r)
    }
  },
  hrService: {
    getEmployers: (): Promise<SerializedEntity<PersonGroupExt>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_HrService",
        "getEmployers",
        {}
      ).then(r => JSON.parse(r));
    },
    isHr: (): Promise<boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_HrService",
        "isHr",
        {}
      ).then(r => JSON.parse(r));
    },
  },
  organizationService: {
    loadOrganizationTree: (params: { parentId: string | null, hierarchyId: string | null, searchText: string | null }, fetchOpts?: FetchOptions): Promise<OrganizationTree[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_OrganizationService",
        "loadOrganizationTree",
        {
          ...params
        },
        fetchOpts
      ).then(r => JSON.parse(r));
    }
  },
  learningBudgetPortalService: {
    loadBudgetApprovmentData: async (learningBudgetId: string): Promise<TableDataParent[]> => {
      const resp = await getCubaREST()!.invokeService<string>(
        'tsadv_LearningBudgetPortalService',
        'loadLearningBudgetData', {
          learningBudgetId: learningBudgetId
        }
      )
      return await JSON.parse(resp)
    }
  },
  punishmentService: {
    getPunishmentByPersonGroup: (param: { personGroupId: string }): Promise<Array<Punishment>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PunishmentService",
        "getPunishmentByPersonGroup",
        {...param}
      ).then(r => JSON.parse(r))
    }
  },
  userHelperService: {
    getUsersFullName: async (logins: string[]): Promise<string[]> => {
      const r = await getCubaREST()!.invokeService<string>(
        'tsadv_UserHelperService',
        'getUsersFullName', {
          logins: logins
        })
      return JSON.parse(r)
    }
  },
  userRegistrationService: {
    getPersonByIin: (iin: string): Promise<PersonGroupExt> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "getPersonByIin",
        {iin: iin}
      ).then(r => JSON.parse(r));
    },
    getUserByIin: (iin: string): Promise<BaseUserExt> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "getUserByIin",
        {iin: iin}
      ).then(r => JSON.parse(r));
    },
    sendSms: (person: string, mobile: string): Promise<Boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "sendSms",
        {person: person, mobile: mobile}
      ).then(r => JSON.parse(r));
    },
    testSms: (person: string, code: string): Promise<Boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "testSms",
        {person: person, code: code}
      ).then(r => JSON.parse(r));
    },
    createUser: (person: string, password: string): Promise<String> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "createUser",
        {person: person, password: password}
      ).then(r => r);
    },
    updatePassword: (person: string, password: string): Promise<Boolean> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_UserRegistrationService",
        "updatePassword",
        {person: person, password: password}
      ).then(r => JSON.parse(r));
    },
    getSmsCodeTimeoutSec: (): Promise<number> => {
      return getCubaREST()!.invokeService<number>(
        "tsadv_UserRegistrationService",
        "getSmsCodeTimeoutSec",
        {}
      ).then(r => {if (!r) return 300; else return r.valueOf()}
      ).catch(action(() =>{return 300}))
    },
  },
  recognitionPortalService: {
    loadUserMedals: async (personGroupId: string):Promise<Array<PersonMedal>> => {
      const resp = await getCubaREST()!.invokeService<string>(
        'tsadv_RecognitionPortalService',
        'loadUserMedals',
        {
          personGroupId: personGroupId
        })

      return JSON.parse(resp)
    },
    loadUserNominations: async (personGroupId: string):Promise<Array<PersonMedalRequest>> => {
      const resp = await getCubaREST()!.invokeService<string>(
        'tsadv_RecognitionPortalService',
        'loadUserNominations',
        {
          personGroupId: personGroupId
        }
      )
      return JSON.parse(resp)
    }
  },
  learningRequestService: {
    approveTd: (params: {
      approvePojo: ApproveTd,
      request: AbstractBprocRequest,
      taskData: ExtTaskData
    }): Promise<void> => {
      return getCubaREST()!.invokeService<void>(
        "tsadv_LearningRequestService",
        "approveTd",
        {...params}
        );
    },
  },
  scoredCompetencyService:{
    getScorecardData:(param:{assignmentId:string}): Promise<any> =>{
      return getCubaREST()!.invokeService<string>(
        'tsadv_PersonCompetenceService',
        'getScorecardData',
        {...param}
      ).then(r => JSON.parse(r))
    },
    getScorecardHistoryData: (param: { assignmentId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<string>(
        'tsadv_PersonCompetenceService',
        'getScorecardHistoryData',
        {...param}
      ).then(r => JSON.parse(r))
    },
    calcResult: (param: { assignmentId: string }): Promise<any> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_PersonCompetenceService",
        "calcResult",
        {...param}
      ).then(r => r)
    }
  },
  vacancyRequestService: {
    getAssistantManagers: (): Promise<SerializedEntity<PersonGroupExt>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacancyRequestService",
        "getAssistantManagers",
        {}
      ).then((r) => JSON.parse(r));
    },
    getChildPositionGroups: (personGroupId: string): Promise<SerializedEntity<PositionGroupExt>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_VacancyRequestService",
        "getChildPositionGroups",
        {personGroupId: personGroupId}
      ).then((r) => JSON.parse(r));
    },
    hasAssistantManager: (entity: AbstractBprocRequest): Promise<boolean> => {
      return getCubaREST()!.invokeService<boolean>(
        "tsadv_VacancyRequestService",
        "hasAssistantManager",
        {entity: entity}
      ).then((r) => r);
    },
    postVacancy: (vacancyRequest: VacancyRequest): Promise<void> => {
      return getCubaREST()!.invokeService<void>(
        "tsadv_VacancyRequestService",
        "postVacancy",
        {vacancyRequest: vacancyRequest}
      ).then((r) => r);
    },

  },
  JobOfferRequestService: {
  //987
    getManagers: ( params: { id: string }, fetchOpts?: FetchOptions): Promise<any> => {
      return getCubaREST()!.invokeService(
        "tsadv_JobOfferService",
        "getManagers",
        {...params},
        fetchOpts
      );
    }
  },
  surChargeRequestService: {
    revision: (param: { entityId: string }): Promise<void> => {
      return getCubaREST()!.invokeService<void>(
        "tsadv_SurChargeService",
        "revision",
        {...param}
      )
    },
  },
  adaptationPlanRequestService: {
    //return -> FileDescriptor
    getJobInstructions: (personGroupId: string): Promise<SerializedEntity<FileDescriptor> | null> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getJobInstructions",
        { personGroupId },
      ).then(r => {
        return r ? JSON.parse(r) : null;
      });
    },
    //return -> PersonGroup
    getManager: async (personGroupId: string): Promise<SerializedEntity<PersonGroupExt>> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getManager",
        { id: personGroupId }
      ).then(r => JSON.parse(r));
    },
    //return -> DocumentsFamiliarization
    getDocumentsForReview: (adaptationPlanId: string): Promise<SerializedEntity<DocumentsFamiliarization>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getDocumentsForReview",
        { entityId: adaptationPlanId }
      ).then(r => JSON.parse(r))
    },
    //return -> PositionCourse
    getPositionCourse: (adaptationPlanId: string): Promise<SerializedEntity<PositionCourse>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getPositionCourse",
        { entityId: adaptationPlanId }
      ).then(r => JSON.parse(r));
    },
    //return -> PersonGroup
    getHrs: (): Promise<SerializedEntity<PersonGroupExt>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getHrs",
        {}
      ).then(r => JSON.parse(r));
    },
    //return -> PersonGroup
    getManagers: async (personGroupId: string):Promise<SerializedEntity<PersonGroupExt>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getManagers",
        { id: personGroupId }
      ).then(r => JSON.parse(r));
    },
    //return -> PersonGroup
    getEmployeeCompanyHrs: (personGroupId: string): Promise<SerializedEntity<PersonGroupExt>[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_AdaptationService",
        "getEmployeeCompanyHrs",
        {id: personGroupId}
      ).then(r => JSON.parse(r));
    },
    getAssessment: async (params: { personGroupId: string, lang: string }): Promise<AdaptationServiceGetAssessmentResult[]> => {
      try {
        const res = await getCubaREST()!.invokeService<string>(
          "tsadv_AdaptationService",
          "getAssessment",
          {...params}
        );
        const data: null | { type: string, value: string } = res ? JSON.parse(res) : null;
        if(data && data.type === "json") {
          return JSON.parse(data.value)
        }
        return []
      } catch (error) {
        return []
      }
    },
  },
  competenceTemplateParticipantService: {
    getVisibleAssessmentParticipantGroupIds: (personParticipantId: string): Promise<string[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_CompetenceTemplateParticipantService",
        "getVisibleAssessmentParticipantGroupIds",
        { personParticipantId }
      ).then(r => JSON.parse(r));
    }
  },
  recognitionChartService: {
    loadRecognitionsCount: (personGroupId: string, period: number): Promise<CountChartPojo[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_RecognitionChartService",
        "loadRecognitionsCount",
        {personGroupId, period}
      ).then(r => JSON.parse(r));
    },
    loadQualities: (personGroupId: string): Promise<QualityChartPojo[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_RecognitionChartService",
        "loadQualities",
        {personGroupId}
      ).then(r => JSON.parse(r));
    },
    loadGoodsOrderCount: (personGroupId: string): Promise<BuyChartPojo[]> => {
      return getCubaREST()!.invokeService<string>(
        "tsadv_RecognitionChartService",
        "loadGoodsOrderCount",
        {personGroupId}
      ).then(r => JSON.parse(r));
    }
  }
};

export type CourseInfo = {
  name: string
  avgRate: number
  preRequisitions: string
  trainers: {
    key:string,
    value:string
  }[]
  startDate: moment.Moment
  endDate: moment.Moment
  finished: number
  certificateUrl: string
  sections: any[]
  logo: string
  comments: Comment[]
  isIssuedCertificate: boolean
  learningProof: string
  rateReviewCount: number
  rating: any[]
  isOnline: boolean
  description: string
  educationPeriod: number
  educationDuration: number
  enrollmentId: string
  selfEnrollment: boolean
}